import React,{Component} from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import IDB from '../idb';

const ProductContext = React.createContext();
//Provider
//Consumer
/*jshint esversion: 6 */

class ProductProvider extends Component{

	constructor(props){
		super(props);
		this.searchtimeout	=  0;
		this.cartTicker		= null;
	}

	state = {
		db:new IDB('orlo_v6')/*jshint ignore:line*/,
        isveg:false,
        containegg:false,
		showsearch:false,
		searchkeyword:'',
		isProductLoaded:false,
		tables:[]/*jshint ignore:line*/,
		captains:[],
		allitemcategories:[],
		itemcategories:[],
		allproducts:[],
		products:[],
		cart:[],
		eventdetail:{showbannerandvideotocaptain:false,sliderinterval:5000, thanksinterval:30},
		isbannerloaded:false,
		isSmallBannerLoaded:false,
		mediaserverurl:null,
		isduplicateorder:false,
		canplaceorder:false,
		isloggedin:false,
		iscaptionlogin:false,
		isstewardlogin:false,
		eventtables:[],
		loggedinname:'',
		canretryorder:false,
		isAllProductLoaded:false,
		defaulteventfile:'',
		defaultbannerfile:'',
		defaultsmallbannerfile:'',
		isdefaultevent:false,
		itemimagelargesize:0,
		additionalimageurl:'',
		eventbanner:[],
		eventthanksbanner:[],
		eventsmallbanner:[],
		placingorder:false,
		isordererror:false,
		isorderadded:false,
		orlo_order_id:'',
		isreorderadded:false,
		re_orlo_order_id:'',
		cartSubTotal:0,
		cartTax:0,
		cartTotal:0,
		cartTotalItem:0,
		restaurantid:0,
		searchProduct:false,
		isvegonly:false,
		tempproduct:null,
		isconfirmopen:false,
		internetstatus:"online",
		lastorderedcart:[],
		isduplicateagreeopen:false,
		showeventseat:false,
		tableid:'',
		ordertableid:'',
		ordertablename:'',
		ordertablecode:'',
		ordertableseatid:'',
		ordertablecaptionid:'',
		ordertablerunnerid:'',
		isvipchecked:false,
		orderingtime:0,
		engagedrunners:[],
		searchrunner:false,
		searchRunnerKeyword:null,
		allengagedrunners:[],
		isorderprocessdone:false,
		orderitemdetails:[],
		isdetailfetched:false,
		pendingqueue:'',
		waitingtime:'',
		pendingitemqty:0,
		haspendingitem:true,
		isActiveDetail:false,
		tokenitemdetails:[],
		tokenitemummary:[],
		alleventcategory:[],
		menuid:'',
		categoryid:'',
		itemtypeid:'',
		eventitemtypes:[],
		isnewcategory:false,
		newcategoryname:'',
		newitemname:'',
		newitemnamehindi:'',
		validmenuid:true,
		validcategoryid:true,
		validitemtypeid:true,
		validnewcategoryname:true,
		validnewitemname:true,
		validnewitemnamehindi:true,
		additemformvalid:false,
		isfeaturedcategory:false,
		iscategoyorderchange:false,
		alleventrunners:[],
		isActiveEditCategoryDetail:false,
		editmenuid:'',
		editcategoryid:'',
		editcategorymenuid:'',
		categoryisactive:false,
		categoryorderby:false,
		isactivenotdelivereditem:false,		
		notdelivereditemname:"",		
		notdeliveredhindiitemname:"",		
		notdelivereditemqty:0,		
		notdelivereddetailid:"",
		notdeliveredproduct_id:"",
		notdeliveredhasoption:false,
		notdelivereditemoption:[],
		showfootercartbar:false,
		showpendingitem:false,
		istableselected:false,
		cancancelitem:false,
		footerbarshowing:"",
		isreordererror:false,
		isreorderprocessdone:false,
		isActiveEditItemDetail:false,
		edititem_id:'',
		edititem_menuid:'',
		edititem_categoryid:'',
		edititem_itemtypeid:'',
		edititem_itemname:'',
		edititem_hindiname:'',
		edititem_islivecounter:'',
		edititem_isoutofstock:'',
		edititem_isselfservice:'',
		orderby:'',
		runnerid:'',
		runnername:'',
		mobilenumber:'',
		runnerrecordid:'',
		activecount:0,
		inactivecount:0,
		stewardid:'',
		stewardtabletype:'',
		stewardname:'',
		iscartopen:false,
		istableopen:false,
		canuseoption:false,
		canrepeatoption:false,
		canremoveoption:false,
		isorderstatusopen:false,
		singlecustomizableitem:[],
		tabledetail:[],
		searchrunnerid:'',
		miscdetail:{filtercount:1,haseggitem:false,hasnonvegitem:false,hasvegitem:false,menucount:0,servicestatus:1},
		imagepreviewstatus:[],
		engagedtableinfo:[],
		showimageatleft:false,
		autoplayvideo:false,
		isThanksBannerLoaded:false,
		defaultthanksbannerfile:'',
		adstobeshown:{},
		theposition:180,
		isitemadding:false,
		isaddeditemprocessing:false,
		isrunnerstatusprocessing:false,
		showbreakpopup:false,
		showcancelpopup:false,
		showsearch_captain:false,
		searchkeyword_captain:'',
		runneritemdetail:[],
		isallrunnerengage:false,
		showhaveyloadmessage:false,
		showoutfordeliverymessage:false,
		outfordeliverymessage:'',
		showsplitorderpopup:false,
		numberofsplit:2,
		catsummary:'',
		totalitemavailable:0,
		availablerunner:0,
		src: null,
		croppedImageUrl: null,
		newimagedata: null,
		crop: {
			unit: '%',
			width: 50,
			height: 50,
			x: 25,
			y: 25,
			aspect: 16 / 16,
		},
		isnonveg: false,
		isallmenu: false,
		menutype: 'all',
		ismenuloaded: false,
		eventmenus:[],
		listeventmenus:[],
		selectdeselecttitle: 'Select All',
		alleventmenu:[],
		tabletype:false,
		alleventtable:[],
		zonenumber:'',
		edititem_zonenumber:'',
		iscustomization:false,
		customization:[{categoryindex:1,customizationname:'',customizationtype:2,orderby:'',customoption:[{optionindex:1,option:''}]}],
		isconfirmed:false,
		isdialogopen:false,
		confirmmessage:'',
		confirmrecordid:'',
		confirmrecordid2:'',
		cbfunctionname:'',
		categoryservicestatus:'',
		isitemmarkdeliveredbyguest:true,
		showitemforcomplain:false,
		recentdeliverrunnerid:'',
		hasgoogleimages:false,
		issearchingiamge:false,
		isoutfordeliveryshowing:false,
		googleimages:[],
		bannerstatus:1,
		haseventproduct:false,
		refresheventtitle:'Refresh',
		isrefreshing:false,
		isdemoorderprocessing:false,
		loggedintabledetail:{},
		isordereditemloaded:false,
		message:'',
		alertcolor:'',
		isprocessing:false,
		bannerarea:1,
		bannertype:1,
		hasimage:false,
		itempreview:"",
		searchKeywordKote:'',
		searchProductKote:false,
		koteresponsedata:{success:false, products:[], categories:[], menus:[]},
		isproductloaded:false,
		allmenus:[],
		allcategories:[],
		productresponsedata:{success:false,mediaserverurl:'',storemenu:[],menunum:0,categories:[],categorynum:0,miscdetail:{},eventdetail:{},products:[],productsnum:0,cart:[]},
		miscmenus:[],
		modalstatus:{addeventmenumodal:false, editeventmenumodal: false, addcategorymodal:false, editcategorymodal:false, additemmodal: false, edititemmodal: false},
		status:1,
		name:'',
		checkedmenuid:'',
		menuservicestatus:'',
		isedited:false,
		recordid:'',
		menuname:'',
		categoryname:'',
		iscartclearingstarted:false,
		lefttimetoclearcart:10,
		iseventtableitemloaded:false,
		upcommingrunners:[],
		runnersystemrequired:true,
		islargepreview:false,
		largepreviewimg:'',
		largepreviewtitle:'',
		largepreviewsubtitle:'',
		isorderremark:false,
		isreleaserunnerpopup:false,
		orderremark:'',
		releasingrunner:false,
	}

	devInArray=(needle, haystack)=> {
		var length = haystack.length;
		for(var i = 0; i < length; i++) {
			if(haystack[i] === needle) return true;
		}
		return false;
	}

	setEventTables = async () => {

		let tempTable		= [];
		let tempCaptain		= [];

		let logincaptiondetail  	= [];
        let loginstewarddetail  	= [];
        let logintabledetail    	= [];

		let loggedineventid		= 0;
		let defaulteventid		= 0;

		let loggedinseatid		= 0;

		let canchangeevent		= false;

		let tempcaptionlogin	= false;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));
		let tempMenuDetail	= JSON.parse(localStorage.getItem('orlo_menudetail'));

		let temploggedintabledetail	= {};

		if(tempMenuDetail !== null)
		{
			tempMenuDetail.eventmenus	= [];
		}
		else
		{
			tempMenuDetail	= {};
		}

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				loggedineventid	= Number(tabledetail.eventid);
				loggedinseatid	= Number(tabledetail.seatid);

				temploggedintabledetail	= tabledetail;
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				loggedineventid	= Number(stewarddetail.eventid);

				temploggedintabledetail	= stewarddetail;

				if(stewarddetail.selectedtable !== undefined)
				{
					this.setState({tableid: Number(stewarddetail.selectedtable.code)});
				}
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				loggedineventid	= Number(captiondetail.eventid);

				temploggedintabledetail	= captiondetail;

				tempcaptionlogin	= true;

				if(captiondetail.selectedtable !== undefined)
				{
					this.setState({tableid: captiondetail.selectedtable.code});
				}
			}
		}

		if(this.state.isdefaultevent)
		{
			var tempArr 	= this.state.defaulteventfile.split("_");
			var tempArr2	= tempArr[1].split(".");

			defaulteventid	= Number(tempArr2[0]);
		}
		
		if((loggedineventid !== defaulteventid) && (loggedineventid > 0 && defaulteventid > 0))
		{
			if(Number(loggedinseatid) > 0)
			{
				let table;
				for(table in this.state.tables)
				{
					const tempTabledetail	= this.state.tables[table];
	
					if((Number(tempTabledetail.seatid) === Number(loggedinseatid) && Number(tempTabledetail.password) === Number(defaulteventid)) && Number(tempTabledetail.canlogin) === 1 && this.state.isdefaultevent === true)
					{
						canchangeevent		= true;
						logintabledetail	= tempTabledetail;

						logintabledetail	= {...logintabledetail, showitemimages:Number(tabledetail.showitemimages), showbanners:Number(tabledetail.showbanners)}
					}
				}
			}

			if(canchangeevent === true)
			{
				let tempkotedetail  = JSON.parse(localStorage.getItem('appe_kotedetail'));

				if(tempkotedetail !== null && (Number(tempkotedetail.eventid) > 0 && tempkotedetail.eventid !== null))
				{
					tempkotedetail.eventid	= defaulteventid;

					this.clearCart();
					localStorage.setItem("orlo_menudetail",JSON.stringify(tempMenuDetail));
					localStorage.setItem("tabledetail",JSON.stringify(logintabledetail));
					localStorage.setItem("captiondetail",JSON.stringify(logincaptiondetail));
					localStorage.setItem("stewarddetail",JSON.stringify(loginstewarddetail));
					localStorage.setItem("appe_kotedetail",JSON.stringify(tempkotedetail));
					localStorage.setItem("isloggedin",true);
					this.setGuestUser();
				}
			}
		}

		axios.get(process.env.REACT_APP_DATA_URL+'eventqrdata.json?t1='+Date.now()) // JSON File Path
		.then( response => {


			let eventTables		= response.data.eventtables;
			let tablesNum		= Object.keys(eventTables).length;

			let eventCaptains	= response.data.eventcaptains;
			let captainsNum		= Object.keys(eventCaptains).length;

			let isdefaultevent			= response.data.defaultevent.isdefaultevent;
			let defaulteventfile		= response.data.defaultevent.defaulteventfile;
			let defaultbannerfile		= response.data.defaultevent.defaultbannerfile;
			let defaultthanksbannerfile	= response.data.defaultevent.defaultthanksbannerfile;
			let defaultsmallbannerfile	= response.data.defaultevent.defaultsmallbannerfile;
			if(tablesNum > 0)
			{
				let table;
				for(table in eventTables)
				{
					const singleTable	= eventTables[table];

					if(Number(singleTable.canonlineorder) === 1)
					{
						tempTable	= [...tempTable, singleTable];
					}
				}
			}

			if(captainsNum > 0)
			{
				let caption;

				for(caption in eventCaptains)
				{
					const singleCaptain	= eventCaptains[caption];
					tempCaptain	= [...tempCaptain, singleCaptain];
				}
			}
			
			this.setState(()=>{
				return{
					tables:tempTable,
					captains:tempCaptain,
					defaulteventfile:defaulteventfile,
					defaultbannerfile:defaultbannerfile,
					defaultsmallbannerfile:defaultsmallbannerfile,
					defaultthanksbannerfile:defaultthanksbannerfile,
					isdefaultevent:isdefaultevent,
					isloggedin:localStorage.getItem('isloggedin'),
					loggedintabledetail:temploggedintabledetail,
					iscaptionlogin:tempcaptionlogin,
				};
			},()=>{
				
				let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
				let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
				let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
				let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

				if(tabledetail || captiondetail || kotedetail || stewarddetail || this.state.isdefaultevent === true)
				{
					let eventid	= "";

					if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
					{
						eventid	= tabledetail.eventid;
					}
					else if(captiondetail !== null && (Number(captiondetail.eventid) > 0  && captiondetail.eventid !== null))
					{
						eventid	= captiondetail.eventid;
					}
					else if(kotedetail !== null && (Number(kotedetail.eventid) > 0 && kotedetail.eventid !== null))
					{
						eventid	= kotedetail.eventid;
					}
					else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
					{
						eventid	= stewarddetail.eventid;
					}

					if(this.state.isdefaultevent)
					{
						var tempArr 	= this.state.defaulteventfile.split("_");
						var tempArr2	= tempArr[1].split(".");

						eventid			= tempArr2[0];
					}

					const{loggedintabledetail}	= this.state;

					if(loggedintabledetail)
					{
						eventid	= loggedintabledetail.eventid;
					}

					if(Number(eventid) > 0)
					{
						if(!this.state.isbannerloaded)
						{
							this.setState(()=>{
								return{
									isbannerloaded:true,
								}
							},()=>{

								this.setEventBanner();

							})
						}

						if(!this.state.showitemforcomplain)
						{
							this.getOrderedItemDetail();
						}

						if(!this.state.iseventtableitemloaded)
						{
							this.setState(()=>{
								return{
									iseventtableitemloaded:true
								}
							},()=>{
								this.setEventProducts();
							})
						}
						this.syncStewardDetail();
						this.checkEngageRunner();
					}
				}
			});
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	setEventBanner = async () =>{

		let tempBannerImage			= [];

		let eventbannerdatafile			= "";
		let eventsmallbannerdatafile	= "";

		if(this.state.isdefaultevent)
		{
			eventbannerdatafile			= this.state.defaultbannerfile;
			eventsmallbannerdatafile	= this.state.defaultsmallbannerfile;
		}
		
		if(eventbannerdatafile === "" && eventsmallbannerdatafile === "")
		{
			return false;
		}

		const{loggedintabledetail}	= this.state;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		let eventid				= 0;
		let bannercategoryid	= 0;
		let showbanner			= 0;

		let istableloggedin		= false;
		let isstewardloggedin	= false;

		if(tabledetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid				= tabledetail.eventid;
				bannercategoryid	= tabledetail.bannercategoryid;
				showbanner			= tabledetail.showbanners;
				istableloggedin		= true;
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				eventid				= stewarddetail.eventid;
				bannercategoryid	= stewarddetail.bannercategoryid;
				showbanner			= stewarddetail.showbanners;
				isstewardloggedin	= true;
			}
		}

		
		if(eventbannerdatafile !== "" && loggedintabledetail.showbanners)
		{
			console.log(`${process.env.REACT_APP_DATA_URL}${eventbannerdatafile}?t=${Date.now()}`);

			eventbannerdatafile	= `eventbanner_${loggedintabledetail.eventid}.json`;

			axios.get(`${process.env.REACT_APP_DATA_URL}${eventbannerdatafile}?t=${Date.now()}`) // JSON File Path
			.then( response => {
	
				let eventbanner	= response.data.eventbanner;
	
				if(eventbanner !== null && eventbanner !== undefined)
				{
					if(Object.keys(eventbanner).length > 0)
					{
						let banner;
						for(banner in eventbanner)
						{
							let canshowbanner	= false;
							let singleBanner	= eventbanner[banner];
							
							

							if(istableloggedin || isstewardloggedin)
							{
								if(((Number(singleBanner.categoryid) === Number(bannercategoryid)) || Number(singleBanner.categoryid) === 0) && Number(eventid) > 0)
								{
									canshowbanner	= true;
								}
								if(!showbanner)
								{
									canshowbanner	= false;
								}
							}
							else
							{
								canshowbanner	= true;
							}
							if(canshowbanner)
							{
								tempBannerImage	= [...tempBannerImage, singleBanner];
							}
						}
						
						this.setState(()=>{
							return{
								isBannerLoaded:true,
								isloggedin:localStorage.getItem('isloggedin'),
								eventbanner:tempBannerImage
							};
						});
					}
					else
					{
						this.setState(()=>{
							return{
								isBannerLoaded:true,
								isloggedin:localStorage.getItem('isloggedin'),
								eventbanner:[]
							};
						});
					}
				}
				else
				{
					this.setState(()=>{
						return{
							isBannerLoaded:true,
							isloggedin:localStorage.getItem('isloggedin'),
							eventbanner:[]
						};
					});
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		}

		if(eventsmallbannerdatafile !== "" && loggedintabledetail.showbanners)
		{
			eventsmallbannerdatafile	= `eventsmallbanner_${loggedintabledetail.eventid}.json`;

			axios.get(`${process.env.REACT_APP_DATA_URL}${eventsmallbannerdatafile}?t=${Date.now()}`) // JSON File Path
			.then( response => {
	
				let eventsmallbanner	= response.data.eventsmallbanner;

				if(eventsmallbanner !== null && eventsmallbanner !== undefined)
				{
					if(Object.keys(eventsmallbanner).length > 0)
					{
						this.setState(()=>{
							return{
								isSmallBannerLoaded:true,
								isloggedin:localStorage.getItem('isloggedin'),
								eventsmallbanner:eventsmallbanner
							};
						});
					}
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		}
	};

	setEventThanksBanner = async () =>{

		let eventthanksbannerdatafile	= "";

		let eventid			= 0;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				eventid	= stewarddetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}

			if(this.state.isdefaultevent)
			{
				var tempArr 	= this.state.defaulteventfile.split("_");
				var tempArr2	= tempArr[1].split(".");

				eventid			= tempArr2[0];
			}
		}

		const{loggedintabledetail}	= this.state;

		if(loggedintabledetail)
		{
			eventid	= loggedintabledetail.eventid;
		}

		if(eventid < 1)
		{
			return;
		}
		else
		{
			eventthanksbannerdatafile	= `eventthanksbanner_${eventid}.json`;
		}

		let tempadditionalimageurl	= "";
		if(process.env.NODE_ENV === 'development'){
			tempadditionalimageurl	= "http://orloevent";
			/*tempadditionalimageurl	= "http://192.168.0.113";*/
		}

		let listedthanksbanner  = JSON.parse(localStorage.getItem(`orlo_event_listed_ads`));

		let templistedads		= [];

		if(listedthanksbanner !== null && listedthanksbanner !== undefined)
		{
			templistedads	= [...listedthanksbanner];
		}

		let adstobeshown		= null;
		let startads			= null;
		let eventthanksbanner	= [];

		if(loggedintabledetail.showbanners)
		{
			axios.get(`${process.env.REACT_APP_DATA_URL}${eventthanksbannerdatafile}?t=${Date.now()}`) // JSON File Path
			.then( response => {

				eventthanksbanner	= response.data.eventthanksbanner;

				if(eventthanksbanner !== null && eventthanksbanner !== undefined)
				{
					if(Object.keys(eventthanksbanner).length > 0)
					{
						let banner;
						for(banner in eventthanksbanner)
						{
							let singleBanner	= eventthanksbanner[banner];

							if(startads === null || startads === undefined)
							{
								startads	= singleBanner;
							}

							if(listedthanksbanner !== null && listedthanksbanner !== undefined)
							{
								let singlelistedads		= listedthanksbanner.find(bannerads => bannerads.id === singleBanner.id);

								if((singlelistedads === undefined || singlelistedads === null) && adstobeshown === null)
								{
									templistedads	= [...templistedads, singleBanner];
		
									adstobeshown	= singleBanner;
								}
							}
						}
					}

					if(adstobeshown === undefined || adstobeshown === null)
					{
						adstobeshown	= startads;
						templistedads	= [adstobeshown];
					}
				}

				this.setState(()=>{
					return{
						isThanksBannerLoaded:true,
						additionalimageurl:tempadditionalimageurl,
						eventthanksbanner:eventthanksbanner,
						adstobeshown:adstobeshown,
					};
				},()=>{
					localStorage.setItem(`orlo_event_listed_ads`,JSON.stringify(templistedads));
				});
			})
			.catch(function (error) {
				console.log(error);
			});
		}
	};

	setEventProducts = async () =>{

		let eventid	= "";

		const{loggedintabledetail}	= this.state;

		if(loggedintabledetail)
		{
			eventid	= loggedintabledetail.eventid;
		}

		if(Number(eventid) < 1)
		{
			return false;
		}

		let tempCart		= [];

		const cartdetails	= await this.state.db.fetchAllCartItem();

		if(cartdetails)
		{
			cartdetails.forEach(item => {
				const singleCartItem = {...item, tempinstock:true};

				tempCart = [...tempCart, singleCartItem];
			});
		}

		axios.get(`${process.env.REACT_APP_DATA_URL}eventdata_${eventid}.json?t=${Date.now()}`) // JSON File Path
		.then( response => {
			let storeProducts	= response.data.products;
			let storeCategory	= response.data.categories;
			let miscdetail		= response.data.miscdetail;
			let itemimagelargesize = response.data.event.itemimagelargesize;

			let productsNum		= Object.keys(storeProducts).length;
			let categoryNum		= Object.keys(storeCategory).length;

			let tempevent		= response.data.event;
			let storeMenu		= response.data.menus;
			let menuNum			= Object.keys(storeMenu).length;

			let mediaurl	= tempevent.imageserver;

			if(tempevent.sliderinterval === null || tempevent.sliderinterval === undefined || Number(tempevent.sliderinterval) < 1)
			{
				tempevent.sliderinterval	= 5000;
			}

			if(tempevent.thanksinterval === null || tempevent.thanksinterval === undefined || Number(tempevent.thanksinterval) < 1)
			{
				tempevent.thanksinterval	= 30;
			}

			this.setState(()=>{
				return{
					productresponsedata:{
						success:response.data.success,
						mediaserverurl:mediaurl,
						storemenu:storeMenu,
						menunum:menuNum,
						categories:storeCategory,
						categorynum:categoryNum,
						miscdetail:miscdetail,
						eventdetail:tempevent,
						products:storeProducts,
						productsnum:productsNum,
						cart:tempCart,
					},
					itemimagelargesize:itemimagelargesize
				}
			},()=>{
				this.setProducts();
			})
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	setProducts = async ()=>{

		const{mediaserverurl, storemenu, menunum, categories, categorynum, miscdetail, eventdetail, products, productsnum, cart} = this.state.productresponsedata;

		const{tables, imagepreviewstatus} = this.state;
		let tempadditionalimageurl	= "";
		if(process.env.NODE_ENV === 'development'){
			tempadditionalimageurl	= "http://orloevent/";
			/*tempadditionalimageurl	= "http://192.168.0.113/";*/
		}

		let tempProducts = [], tempCategory = [], tempCategory2 = [], tempcheckcatarr = [], tempMenu = [],tempMenu2 = [], tempcheckmenuarr = [], tempPreviewStatus = imagepreviewstatus, tempEventTable = [], tempeventid = "", tempcaptionlogin = false, tempstewardlogin = false, temploggedinname = "", temptabletype = 0, stewardtable = [], tempStoreProducts	= [], menutype = "all", assignedeventmenus	= [], tempMenuCheckArr	= [], tempCart = cart, selectedtable = "";

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
		{
			tempeventid			= tabledetail.eventid;
			temploggedinname	= tabledetail.title;
		}
		else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
		{
			tempstewardlogin	= true;
			tempeventid			= stewarddetail.eventid;
			temptabletype		= Number(stewarddetail.tabletype);
			temploggedinname	= "Steward #"+stewarddetail.stewardid;
			stewardtable		= stewarddetail.stewardtable;
		}
		else if(captiondetail !== null && (Number(captiondetail.eventid) > 0  && captiondetail.eventid !== null))
		{
			tempcaptionlogin	= true;
			tempeventid			= captiondetail.eventid;
			temploggedinname	= "Captain #"+captiondetail.runnerid;
		}
		else if(kotedetail !== null && (Number(kotedetail.eventid) > 0 && kotedetail.eventid !== null))
		{
			tempeventid			= kotedetail.eventid;
			tempcaptionlogin	= true;
			temploggedinname	= "";
		}

		let tempSelectedEventMenu	= JSON.parse(localStorage.getItem('orlo_menudetail'));
		if(tempSelectedEventMenu !== null)
		{
			menutype			= tempSelectedEventMenu.menutype;
			assignedeventmenus	= tempSelectedEventMenu.eventmenus;

			assignedeventmenus.forEach(menu => {

				tempMenuCheckArr.push(menu.menuid);
			});
		}

		if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
		{
			selectedtable	= tables.find(singletable => Number(singletable.code) === Number(tabledetail.code));
		}
		/*if(selectedtable !== undefined && selectedtable !== "" && selectedtable !== null && (selectedtable.menutype !== menutype))*/
		if(selectedtable !== undefined && selectedtable !== "" && selectedtable !== null)
		{
			tempMenuCheckArr	= [];

			let menudata	= {menutype:selectedtable.menutype, eventmenus:selectedtable.assignedmenu};

			menutype	= selectedtable.menutype;

			localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));

			Object.keys(selectedtable.assignedmenu).length > 0 && selectedtable.assignedmenu.forEach(menu => {

				tempMenuCheckArr.push(menu.menuid);
			});
			
			if(menutype !== 'selected')
			{
				tempMenuCheckArr	= [];
			}
		}
		if(tables)
		{
			tables.forEach(table => {
				const singleTable = {...table};

				if(tempstewardlogin === true && Number(temptabletype) === 1)
				{
					if(Number(singleTable.eventid) === Number(tempeventid) && this.devInArray(singleTable.seatid, stewardtable))
					{
						tempEventTable = [...tempEventTable, singleTable];
					}
				}
				else
				{
					if(Number(singleTable.eventid) === Number(tempeventid))
					{
						tempEventTable = [...tempEventTable, singleTable];
					}
				}
			});
		}

		let storeProducts	= products;
		let storeCategory	= categories;

		let productsNum		= productsnum;
		let categoryNum		= categorynum;

		let tempevent		= eventdetail;
		let storeMenu		= storemenu;
		let menuNum			= menunum;

		if(tempevent.sliderinterval === null || tempevent.sliderinterval === undefined || Number(tempevent.sliderinterval) < 1)
		{
			tempevent.sliderinterval	= 5000;
		}

		if(tempevent.thanksinterval === null || tempevent.thanksinterval === undefined || Number(tempevent.thanksinterval) < 1)
		{
			tempevent.thanksinterval	= 30;
		}

		if(productsNum > 0)
		{
			let item;
			for(item in storeProducts)
			{
				let singleItem		= storeProducts[item];

				const id			= singleItem.id;
				const isvegfood		= singleItem.isvegfood;
				const containsegg	= singleItem.containsegg;
				const catid			= singleItem.catid;
				const instock		= singleItem.instock;
				const price			= singleItem.price;
				const menuid		= singleItem.menuid;
				const zonenumber	= singleItem.zonenumber;

				let itemcount		= 0;
				let itemtotal		= 0;
				let canshowitem		= false;
				let isstockout		= false;
				let isnonvegfood	= true;

				let orgSingleProduct	= tempStoreProducts.find(orgSingleItem => orgSingleItem.id === id);
				let cartProduct			= tempCart.find(cartitem => cartitem.id === id);

				if(orgSingleProduct)
				{
					itemcount		= 0;
					itemtotal		= 0;
				}

				if(isvegfood || containsegg || !singleItem.showvegicon)
				{
					isnonvegfood	= false;
				}

				if(cartProduct)
				{
					singleItem.total	= cartProduct.total;
					itemtotal			= cartProduct.total;

					if(singleItem.iscustomization)
					{
						let tempcount	= 0;

						const customizationTempCart	= tempCart.filter(tempcartitem => tempcartitem.id === id);

						customizationTempCart.forEach((customizeitem)=>{

							const singlecustomizeitem = {...customizeitem};

							tempcount	+= singlecustomizeitem.count;
						});

						itemcount	= tempcount;
					}
					else
					{
						itemcount	= cartProduct.count;
					}
					
					cartProduct.instock	= instock;
					singleItem.inCart	= true;

					isstockout = false;
				}

				if(instock === false)
				{
					isstockout = true;
				}

				if(orgSingleProduct)
				{
					singleItem	= {...singleItem, busy:orgSingleProduct.busy, tempinstock:orgSingleProduct.tempinstock, hasvote:orgSingleProduct.hasvote, hastag:orgSingleProduct.hastag, canuseoption:orgSingleProduct.canuseoption, canrepeatoption:orgSingleProduct.canrepeatoption, customitemqty:orgSingleProduct.customitemqty, baseprice:orgSingleProduct.baseprice, optiontotal:orgSingleProduct.optiontotal, count:itemcount, total:itemtotal, inCart:orgSingleProduct.inCart, iszoneediting:false, tempzonenumber:zonenumber, zonenumber2:'---', haslargeimage2:false};
				}
				else
				{
					singleItem	= {...singleItem, busy:false, tempinstock:true, hasvote:false, hastag:false, canuseoption:false, canrepeatoption:false, customitemqty:1, baseprice:price, optiontotal:0, count:itemcount, total:itemtotal, iszoneediting:false, tempzonenumber:zonenumber, zonenumber2:'---', haslargeimage2:false};
				}

				let previewProduct	= tempPreviewStatus.find(previewitem => previewitem.id === id);

				if(previewProduct !== undefined && previewProduct !== null)
				{
					singleItem.islargepreview	= previewProduct.islargepreview;
					singleItem.showitempreview	= previewProduct.showitempreview;
				}
				else
				{
					singleItem.islargepreview	= false;

					let singlePreviewItem	= {id:id, islargepreview:false, showitempreview:false};

					tempPreviewStatus = [...tempPreviewStatus, singlePreviewItem];
				}

				if(this.state.isvegonly === true)
				{
					if(isvegfood === true)
					{
						canshowitem	= true;
					}
				}
				if(this.state.containegg === true)
				{
					if(containsegg === true)
					{
						canshowitem	= true;
					}
				}
				if(this.state.isnonveg === true)
				{
					if(isnonvegfood === true)
					{
						canshowitem	= true;
					}
				}

				if(!this.state.isvegonly && !this.state.containegg && !this.state.isnonveg)
				{
					canshowitem	= true;
				}

				if(this.state.showsearch === true && this.state.searchkeyword !== undefined)
				{
					if(singleItem.title.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1)
					{
						canshowitem	= true;
					}
					else
					{
						canshowitem	= false;
					}
				}

				if(isstockout === true)
				{
					canshowitem = false;
				}

				if(menutype === 'selected')
				{
					if(tempMenuCheckArr.indexOf(menuid) === -1)
					{
						canshowitem = false;
					}
				}
				
				if(canshowitem === true)
				{
					tempProducts = [...tempProducts, singleItem];
					if(tempcheckcatarr.indexOf(catid) === -1)
					{
						tempcheckcatarr.push(catid);
						tempCategory2[catid] = 1;
					}
					else
					{
						tempCategory2[catid] += 1;
					}

					if(tempcheckmenuarr.indexOf(menuid) === -1)
					{
						tempcheckmenuarr.push(menuid);
						tempMenu2[menuid] = 1;
					}
					else
					{
						tempMenu2[menuid] += 1;
					}
				}
			}

			if(categoryNum > 0)
			{
				let cat;
				for(cat in storeCategory)
				{
					const singleCat		= storeCategory[cat];
					const id			= singleCat.id;

					if(tempCategory2[id] > 0)
					{
						singleCat.categorycount	= tempCategory2[id];
						singleCat.newcount		= tempCategory2[id];

						tempCategory	= [...tempCategory, singleCat];
					}
				}
			}

			if(menuNum > 0)
			{
				let menu;
				for(menu in storeMenu)
				{
					const singleMenu	= storeMenu[menu];
					const id			= singleMenu.menuid;

					if(tempMenu2[id] > 0)
					{
						singleMenu.menucount	= tempMenu2[id];
						singleMenu.newcount		= tempMenu2[id];
	
						tempMenu	= [...tempMenu, singleMenu];
					}
				}
			}

			if(tabledetail)
			{
				let lastitemaddedtime  = tabledetail.lastitemaddedtime;

				if(((lastitemaddedtime+(60000*Number(process.env.REACT_APP_CARTCHECKTIME)) - 10000) < Date.now()) && !this.state.iscartclearingstarted && Number(this.state.cartTotalItem) > 0 && !this.state.iscaptionlogin)
				{
					this.setState(()=>{
						return{
							iscartclearingstarted:true,
							isdialogopen:true,
							confirmmessage:`Your have ${this.state.cartTotalItem} items in cart over ${Number(process.env.REACT_APP_CARTCHECKTIME)} min. What would you like to do?`,
						}
					},()=>{
						this.startCartClearing();
					})
				}
			}

			this.setState(()=>{
				return{
					products: tempProducts,
					isProductLoaded:true,
					cart:tempCart,
					itemcategories:tempCategory,
					eventdetail:tempevent,
					isBannerLoaded:true,
					mediaserverurl:mediaserverurl,
					isduplicateorder:false,
					canplaceorder:false,
					isloggedin:localStorage.getItem('isloggedin'),
					iscaptionlogin:tempcaptionlogin,
					isstewardlogin:tempstewardlogin,
					eventtables:tempEventTable,
					loggedinname:temploggedinname,
					canretryorder:tempevent.canretryorder,
					additionalimageurl:tempadditionalimageurl,
					tabledetail:tabledetail,
					miscdetail:miscdetail,
					imagepreviewstatus:tempPreviewStatus,
					ismenuloaded:true,
					eventmenus:tempMenu,
					haseventproduct:true,
					showimageatleft:tempevent.showimageatleft,
				};
			},()=>{

				const {cart} = this.state;

				if(Object.keys(cart).length > 0)
				{
					let tempCart	= [...cart];

					cart.forEach(async (singlecartitem)=>{

						const product = storeProducts.find(item => item.id === singlecartitem.id);

						if(product)
						{
							const {id, instock, isselfservice} = product;

							if(isselfservice || !instock)
							{
								tempCart		= tempCart.filter(cartitem => cartitem.id !== id);
								
								let removedProduct	= product;
						
								removedProduct.inCart	= false;
								removedProduct.count	= 0;
								removedProduct.total	= 0;
						
								await this.state.db.deleteCartItem(removedProduct);
							}
						}
					})

					this.setState(()=>{
						return{
							cart:[...tempCart],
						}
					},()=>{
						this.addTotals();
					})
				}
				else
				{
					this.addTotals();
				}
				this.syncCategoryCount();
				this.setItemImageLoaded();
			});
		}
		else
		{
			this.setState(()=>{
				return{
					products: tempProducts,
					isProductLoaded:true,
					cart:tempCart,
					itemcategories:tempCategory,
					eventdetail:tempevent,
					isBannerLoaded:true,
					mediaserverurl:mediaserverurl,
					isduplicateorder:false,
					canplaceorder:false,
					isloggedin:localStorage.getItem('isloggedin'),
					iscaptionlogin:tempcaptionlogin,
					isstewardlogin:tempstewardlogin,
					eventtables:tempEventTable,
					loggedinname:temploggedinname,
					canretryorder:tempevent.canretryorder,
					additionalimageurl:tempadditionalimageurl,
					tabledetail:tabledetail,
					miscdetail:miscdetail,
					imagepreviewstatus:tempPreviewStatus,
					ismenuloaded:true,
					eventmenus:tempMenu,
					haseventproduct:false,
				};
			},()=>{
				this.addTotals();
				this.syncCategoryCount();
				this.setItemImageLoaded();
			});
		}
    }

	setItemImageLoaded=()=>{

		const{loggedintabledetail, imagepreviewstatus}	= this.state;

		let tempPreviewStatus	= imagepreviewstatus;

		if(loggedintabledetail.showitemimages)
		{
			setTimeout(() => {

				const tempProducts	= [...this.state.products];
		
				tempProducts.forEach((singleitem)=>{

					let previewProduct	= tempPreviewStatus.find(previewitem => previewitem.id === singleitem.id);
			
					singleitem.showitempreview	= true;

					if(previewProduct !== undefined && previewProduct !== null)
					{
						previewProduct.showitempreview = true;
					}
					else
					{
						singleitem.islargepreview	= false;

						let singlePreviewItem	= {id:singleitem.id, islargepreview:false, showitempreview:singleitem.showitempreview};

						tempPreviewStatus = [...tempPreviewStatus, singlePreviewItem];
					}
				})
		
				this.setState(()=>{
					return{
						products:tempProducts,
						imagepreviewstatus:tempPreviewStatus,
					}
				})
	
			}, 200);
		}
	}

	getItem = (id) =>{
		const product = this.state.products.find(item => item.id === id);
		return product;
	};

	getLiveItem = (id) =>{
		const product = this.state.allproducts.find(item => item.id === id);
		return product;
	};

	showBusy=(id)=>{

		let tempProducts	= [...this.state.products];
		const index			= tempProducts.indexOf(this.getItem(id));
		const product		= tempProducts[index];

		if(product)
		{
			product.busy		= true;

			this.setState(()=>{
				return {products:tempProducts};
			})
		}
	}

	hideBusy=(id)=>{

		setTimeout(() => {
			let tempProducts	= [...this.state.products];
			const index			= tempProducts.indexOf(this.getItem(id));
			const product		= tempProducts[index];

			if(product)
			{
				product.busy		= false;
				this.setState(()=>{
					return {isduplicateorder:false,canplaceorder:false,products:tempProducts};
				})
			}

		}, 500);
	}

	toggleItemOption = (id) =>{

		let tempProducts		= [...this.state.products];

		if(tempProducts !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			const index				= tempProducts.indexOf(this.getItem(id));
			const product			= tempProducts[index];
	
			product.canuseoption	= !product.canuseoption;
			product.canrepeatoption	= false;
	
			if(product.iscustomization === 1)
			{
				product.itemoptions.forEach((optiongroup)=>{
	
					optiongroup.selected	= false;

					if(optiongroup.options !== undefined)
					{
						optiongroup.options.forEach((option)=>{

							if(option !== undefined)
							{
								option.checked = false;
							}
						})
					}
				})
		
				product.customitemqty	= 1;
			}
	
			this.setState(()=>{
				return{
					products:tempProducts,
				}
			},()=>{
				
				const singlecustomizableitem	= this.state.products.find(singleproduct=>singleproduct.id === id);
	
				this.setState(()=>{
					return{
						canuseoption:!this.state.canuseoption,
						canrepeatoption:false,
						singlecustomizableitem:singlecustomizableitem,
					}
				},()=>{
					if(this.state.canuseoption)
					{
						document.body.style.overflow = "hidden";
					}
					else
					{
						this.setState({
							singlecustomizableitem:[],
						},()=>{
							document.body.style.overflow = "auto";
						})
					}
				})
			})
		}
	}

	toggleItemRepeatOption = (id) =>{

		let tempProducts		= [...this.state.products];

		if(tempProducts !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			const index				= tempProducts.indexOf(this.getItem(id));
			const product			= tempProducts[index];
	
			product.canrepeatoption	= !product.canrepeatoption;
			product.canuseoption	= false;
	
			if(product.canuseoption === false)
			{
				product.itemoptions.forEach((optiongroup)=>{
	
					optiongroup.selected	= false;
	
					optiongroup.options.forEach((option)=>{
						option.checked = false;
					})
				})
	
				product.customitemqty	= 1;
			}
	
			const singlecustomizableitem	= this.state.products.find(singleproduct=>singleproduct.id === id);
	
			this.setState(()=>{
				return {
					products:tempProducts,
					singlecustomizableitem:singlecustomizableitem,
					canrepeatoption:!this.state.canrepeatoption,
				};
			},()=>{
				if(this.state.canrepeatoption === true)
				{
					document.body.style.overflow = "hidden";
				}
				else
				{
					this.setState({
						singlecustomizableitem:[]
					},()=>{
	
						document.body.style.overflow = "auto";
	
					})
				}
			})
		}
	}

	toggleRemoveItemOption = (id) =>{

		let tempProducts		= [...this.state.products];

		if(tempProducts !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			const index				= tempProducts.indexOf(this.getItem(id));
			const product			= tempProducts[index];
	
			product.canuseoption	= false;
			product.canrepeatoption	= false;
	
			if(product.iscustomization === 1)
			{
				product.itemoptions.forEach((optiongroup)=>{
	
					optiongroup.selected	= false;
	
					optiongroup.options.forEach((option)=>{
						option.checked = false;
					})
				})
	
				product.customitemqty	= 1;
			}
	
			this.setState(()=>{
				return{
					products:tempProducts,
				}
			},()=>{
	
				const singlecustomizableitem	= this.state.products.find(singleproduct=>singleproduct.id === id);
	
				this.setState(()=>{
					return{
						canremoveoption:!this.state.canremoveoption,
						canrepeatoption:false,
						singlecustomizableitem:singlecustomizableitem,
					}
				},()=>{
					if(this.state.canremoveoption)
					{
						document.body.style.overflow = "hidden";
					}
					else
					{
						this.setState({
							singlecustomizableitem:[],
						},()=>{
							document.body.style.overflow = "auto";
						})
					}
				})
			})
		}
	}

	addToCart = (id, hasoption) =>{

		let tempProducts	= [...this.state.products];

		let customizationerrormsg	= "";
		let selectedgroupid			= 0;

		if(tempProducts !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			Promise.all([this.showBusy(id)])
			.then(async () => {

				if((Number(this.state.cartTotalItem) >= Number(this.state.eventdetail.throttle_order_items)) && Number(this.state.eventdetail.throttle_order_items) > 0)
				{
					this.toastMessage(`Sorry, Maximum ${this.state.eventdetail.throttle_order_items} item can be ordered at a time.`, 'error');
					return false;
				}
				else
				{
					this.setState({isitemadding:true});
		
					const index			= tempProducts.indexOf(this.getItem(id));
					const product		= tempProducts[index];

					if(product !== undefined)
					{
						product.inCart		= true;
						const price			= product.price;
		
						if(hasoption === false)
						{
							product.count	= 1;
							product.total	= price;
						}
						else
						{
							product.itemoptions.forEach((optiongroup)=>{

								let isitemoptionselected	= false;

								optiongroup.options.forEach((option)=>{
					
									if(option.checked)
									{
										isitemoptionselected	= true;
									}
								})

								if((Number(optiongroup.isrequired) === 0 && optiongroup.isnotrequiredchecked) || Number(optiongroup.isrequired) === 1)
								{
									if(!isitemoptionselected)
									{
										optiongroup.showerror	= true;

										if(selectedgroupid === "" || Number(selectedgroupid) < 1)
										{
											selectedgroupid	= optiongroup.customcatid;
										}

										if(customizationerrormsg === "")
										{
											customizationerrormsg	+= `${optiongroup.name}`;
										}
										else
										{
											customizationerrormsg	+= `, ${optiongroup.name}`;
										}
									}
								}
							})
							if(customizationerrormsg !== "")
							{
								this.hideBusy(id);

								product.inCart	= false;

								this.setState(()=>{
									return {
										isaddeditemprocessing:false,
										isitemadding:false,
										products:tempProducts,
									};
								})

								const topPos = document.getElementById(`optiongroup${selectedgroupid}`).offsetTop;
								document.getElementById('itemoptiongroupcontainer').scrollTop = topPos-60;

								this.toastMessage(`Please select ${customizationerrormsg}`, 'error');
								return false;
							}
							product.canuseoption	= false;
							product.canrepeatoption	= false;
		
							let tempcount	= product.customitemqty;
				
							product.count	= tempcount;
							product.total	= price * tempcount;
						}

						await this.state.db.addNewItemInCart(product);

						let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

						if(tabledetail)
						{
							tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
							localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
						}

						this.setState(()=>{
							return {
								canuseoption:false,
								canrepeatoption:false,
								singlecustomizableitem:[],
								isduplicateorder:false,
								products:tempProducts,
								cart:[...this.state.cart, product],
								isaddeditemprocessing:false,
								isitemadding:false,
							};
						},()=>{
							document.body.style.overflow = "auto";
		
							this.hideBusy(id);
							this.addTotals();
						})
					}
				}
			})
		}
	}

	increment = async(id) => {

		let tempProduct		= [...this.state.products];

		if(tempProduct !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			this.showBusy(id);
	
			if((Number(this.state.cartTotalItem) >= Number(this.state.eventdetail.throttle_order_items)) && Number(this.state.eventdetail.throttle_order_items) > 0)
			{
				this.toastMessage(`Sorry, Maximum ${this.state.eventdetail.throttle_order_items} item can be ordered at a time.`, 'error');
				return false;
			}
			else
			{
				this.setState({isaddeditemprocessing:true});

				let tempCart			= [...this.state.cart];

				const selectedCartProduct	= tempCart.find(cartitem=>cartitem.id === id);

				const cartindex		= tempCart.indexOf(selectedCartProduct);
				let cartproduct		= [];

				let latestcartitem	= [];

				let tempcount	= 0;

				if(Number(selectedCartProduct.iscustomization) === 1)
				{
					const customizationTempCart	= tempCart.filter(tempcartitem => tempcartitem.id === id);

					customizationTempCart.forEach((customizeitem)=>{

						const singlecustomizeitem = {...customizeitem};

						latestcartitem	= singlecustomizeitem;

						tempcount	+= singlecustomizeitem.count;
					});
				}
				else
				{
					latestcartitem	= tempCart[cartindex];
					tempcount		= latestcartitem.count;
				}

				cartproduct	= latestcartitem;

				cartproduct.count	= cartproduct.count + 1;
				cartproduct.total	= cartproduct.price * cartproduct.count;

				const selectedStoreProduct	= tempProduct.find(item=>item.id === id);
				const storeitemindex		= tempProduct.indexOf(selectedStoreProduct);
				const storeproduct			= tempProduct[storeitemindex];
		
				storeproduct.count	= tempcount+1;
				storeproduct.total	= latestcartitem.total;

				storeproduct.canuseoption		= false;
				storeproduct.canrepeatoption	= false;

				selectedCartProduct.count	= cartproduct.count;
				selectedCartProduct.total	= cartproduct.total;

				await this.state.db.updateCartItem(cartproduct);

				let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

				if(tabledetail)
				{
					tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
					localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
				}
			
				this.setState(
					()=>{
						return{
							isduplicateorder:false,
							canplaceorder:false,
							canuseoption:false,
							canrepeatoption:false,
							isaddeditemprocessing:false,
							isitemadding:false,
							cart:[...tempCart],
						};
					},
					()=>{
						document.body.style.overflow = "auto";
						this.hideBusy(id);
						this.addTotals();
					}
				);
			}
		}
	}

	incrementCustomOption_org = async(tempcartid) => {

		let tempCart	= [...this.state.cart];

		if(tempCart !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			const cartproduct	= tempCart.find(cartitem=>cartitem.tempcartid === tempcartid);

			cartproduct.count	= cartproduct.count + 1;
			cartproduct.total	= cartproduct.price * cartproduct.count;
	
			await this.state.db.updateCartItem(cartproduct);

			let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

			if(tabledetail)
			{
				tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
				localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
			}
		
			this.setState(
				()=>{
					return{
						isduplicateorder:false,
						canplaceorder:false,
						canuseoption:false,
						canrepeatoption:false,
					};
				},
				()=>{
					document.body.style.overflow = "auto";

					this.setProducts();
				}
			);
		}
	}

	incrementCustomOption = async (tempcartid) => {

		let tempCart	= [...this.state.cart];

		if(tempCart !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			const selectedCartProduct	= tempCart.find(item=>item.tempcartid === tempcartid);

			this.setState({isaddeditemprocessing:true});
	
			const cartindex		= tempCart.indexOf(selectedCartProduct);
			const cartproduct	= tempCart[cartindex];
	
			if(cartproduct !== undefined)
			{
				let tempcount	= cartproduct.count + 1;
	
				cartproduct.count	= tempcount;
	
				cartproduct.total	= cartproduct.price * cartproduct.count;
	
				await this.state.db.updateCartItem(cartproduct);

				let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

				if(tabledetail)
				{
					tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
					localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
				}
	
				this.setState(
					()=>{
						return{
							isduplicateorder:false,
							canplaceorder:false,
							cart:[...tempCart],
							isaddeditemprocessing:false,
							isitemadding:false,
						};
					},
					()=>{
						document.body.style.overflow = "auto";

						this.setProducts();
					}
				);
			}
		}
	}

	decrement = async (id) => {

		let tempProduct			= [...this.state.products];

		if(tempProduct !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			this.showBusy(id);
			
			this.setState({isaddeditemprocessing:true});

			let tempCart			= [...this.state.cart];
	
			const selectedCartProduct	= tempCart.find(item=>item.id === id);
	
			const cartindex		= tempCart.indexOf(selectedCartProduct);
			const cartproduct	= tempCart[cartindex];
	
			if(cartproduct !== undefined)
			{
				cartproduct.count	= cartproduct.count - 1;
	
				const selectedStoreProduct	= tempProduct.find(item=>item.id === id);
				const storeitemindex		= tempProduct.indexOf(selectedStoreProduct);
				const storeproduct			= tempProduct[storeitemindex];
		
				storeproduct.count	= cartproduct.count;
		
				if(cartproduct.count === 0)
				{
					this.removeItem(id);
				}
				else
				{
					cartproduct.total	= cartproduct.price * cartproduct.count;
		
					storeproduct.total	= cartproduct.total;
		
					await this.state.db.updateCartItem(cartproduct);

					let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

					if(tabledetail)
					{
						tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
						localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
					}
		
					this.setState(
						()=>{
							return{
								isduplicateorder:false,
								canplaceorder:false,
								products:[...tempProduct],
								cart:[...tempCart],
								isaddeditemprocessing:false,
								isitemadding:false,
							};
						},
						()=>{
							document.body.style.overflow = "auto";
							this.hideBusy(id);
							this.addTotals();
						}
					);
				}
			}
		}
	}

	decrementCustomOption = async (tempcartid) => {

		let tempCart	= [...this.state.cart];

		if(tempCart !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			const selectedCartProduct	= tempCart.find(item=>item.tempcartid === tempcartid);

			this.setState({isaddeditemprocessing:true});
	
			const cartindex		= tempCart.indexOf(selectedCartProduct);
			const cartproduct	= tempCart[cartindex];
	
			if(cartproduct !== undefined)
			{
				let tempcount	= cartproduct.count - 1;
	
				if(tempcount === 0)
				{
					this.removeItemCustomOption(tempcartid);
				}
				else
				{
					if(tempcount < 1)
					{
						tempcount	= 1;
					}
					cartproduct.count	= tempcount;
		
					cartproduct.total	= cartproduct.price * cartproduct.count;
		
					await this.state.db.updateCartItem(cartproduct);

					let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

					if(tabledetail)
					{
						tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
						localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
					}
		
					this.setState(
						()=>{
							return{
								isduplicateorder:false,
								canplaceorder:false,
								cart:[...tempCart],
								isaddeditemprocessing:false,
								isitemadding:false,
							};
						},
						()=>{
							document.body.style.overflow = "auto";

							this.setProducts();
						}
					);
				}
			}
		}
	}

	removeItem = async (id) => {

		let tempProducts	= [...this.state.products]; /*here three dots is a feature called destructuring and assigned current product to a temp product*/

		if(tempProducts !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			this.showBusy(id);

			this.setState({isaddeditemprocessing:true});
	
			let tempCart		= [...this.state.cart];
	
			tempCart	= tempCart.filter(item => item.id !== id);
			const index	= tempProducts.indexOf(this.getItem(id));
	
			let removedProduct	= tempProducts[index];
	
			removedProduct.inCart	= false;
			removedProduct.count	= 0;
			removedProduct.total	= 0;
	
			await this.state.db.deleteCartItem(removedProduct);

			let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

			if(tabledetail)
			{
				tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
				localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
			}

			this.setState(
				()=>{
					return{
						isduplicateorder:false,
						canplaceorder:false,
						cart:[...tempCart],
						products:[...tempProducts],
						isaddeditemprocessing:false,
						isitemadding:false,
					};
				},
				()=>{
					document.body.style.overflow = "auto";
					this.hideBusy(id);
					this.addTotals();
				}
			);
		}
	}

	removeItemCustomOption = async (tempcartid) => {

		this.setState({isaddeditemprocessing:true});

		let tempCart	= [...this.state.cart];

		if(tempCart !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			const removedProduct	= tempCart.find(item => item.tempcartid === tempcartid);

			removedProduct.inCart	= false;
			removedProduct.count	= 0;
			removedProduct.total	= 0;
	
			await this.state.db.deleteCartItemOption(removedProduct);

			let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

			if(tabledetail)
			{
				tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
				localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
			}

			this.setState(
				()=>{
					return{
						isduplicateorder:false,
						canplaceorder:false,
						isaddeditemprocessing:false,
						isitemadding:false,
					};
				},
				()=>{

					this.setProducts();
				}
			);
		}
	}

	clearCart = async () => {
		
		this.setState(()=>{
			return{
				isaddeditemprocessing:true
			}
		},()=>{

			this.state.db.addLastOrderedCart();
			this.state.db.deleteAllCartItem();
	
			let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));
	
			if(tabledetail)
			{
				tabledetail  = {...tabledetail, lastitemaddedtime:'', cartupdatedtime:''};
				localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
			}
	
			let tempProducts	= [];
	
			const{products} = this.state;
	
			if(products)
			{
				products.forEach(item => {
					const singleItem = {...item, total:0, count:0, inCart:false};
	
					tempProducts = [...tempProducts, singleItem];
				});
			}
	
			this.setState(()=>{
				return {
					isduplicateorder:false,
					canplaceorder:false,
					cart:[],
					isaddeditemprocessing:false,
					isitemadding:false,
					lefttimetoclearcart:9,
					iscartclearingstarted:false,
					isdialogopen:false,
					confirmmessage:'',
					iscartopen:false,
					products:tempProducts,
					cartSubTotal:0,
					cartTax:0,
					cartTotal:0,
					cartTotalItem:0,
				};
			},
			()=>{
				clearInterval(this.cartTicker);
				document.body.style.overflow = "auto";
			});
		});
	};

	addTotals = async() =>{

		let subTotal = 0;
		let cartItem = 0;

		let tempCart	= [];

		const cartdetails	= await this.state.db.fetchAllCartItem();

		if(cartdetails)
		{
			cartdetails.forEach(item => {
				const singleCartItem = {...item, tempinstock:true};

				tempCart = [...tempCart, singleCartItem];
			});
		}

		this.setState(()=>{
			return{
				cart:tempCart
			}
		},()=>{
			this.state.cart.map(item=>{
				return{
					isduplicateorder:false,
					subTotal: subTotal += item.total,
					cartItem: cartItem += item.count
				};
			});

			const tempTax	= subTotal * 0; /*here 0.1 is temp tax and can be dynamic*/
			const tax		= parseFloat(tempTax.toFixed(2));
			const total		= subTotal + tax;

			this.setState(()=>{
				return{
					cartSubTotal:subTotal,
					cartTax:tax,
					cartTotal:total,
					cartTotalItem:cartItem
				}
			})
		})
	}

	handleOptionSelection = (id, categoryid, optionid, type) =>{

		let tempProducts	= [...this.state.products];

		if(tempProducts !== undefined)
		{
			const index			= tempProducts.indexOf(this.getItem(id));
			const product		= tempProducts[index];
	
			let hasselectedoption	= false;

			let tempOptionGroup	= product.itemoptions.find(optiongroup => optiongroup.customcatid === categoryid);
			let tempOption		= [...tempOptionGroup.options];
	
			if(type === 'radio')
			{
				tempOption.forEach((item)=>{
					item.checked = false;
				});
			}
	
			let tempItemOption	= tempOption.find(itemoption => itemoption.optionid === optionid);
	
			tempItemOption.checked	= !tempItemOption.checked;
	
			tempOption.forEach((option)=>{
	
				if(option.checked === true)
				{
					hasselectedoption = true;				
				}
			})
	
			let tempoptionprice	= 0;
	
			product.itemoptions.forEach((optiongroup)=>{
	
				optiongroup.options.forEach((option)=>{
	
					if(option.checked)
					{
						tempoptionprice	+= option.optionprice;
					}
				})
			})
	
			product.optiontotal	= tempoptionprice;
			product.price		= product.baseprice + tempoptionprice;
	
			tempOptionGroup.selected	= hasselectedoption;
			tempOptionGroup.showerror	= false;
	
			this.setState(()=>{
				return {
					products:tempProducts,
				};
			})
		}
	}

	incrementCustomItem = (id) => {

		let tempProduct			= [...this.state.products];

		if(tempProduct !== undefined)
		{
			const selectedStoreProduct	= tempProduct.find(item=>item.id === id);

			const storeitemindex		= tempProduct.indexOf(selectedStoreProduct);
			const storeproduct			= tempProduct[storeitemindex];
	
			storeproduct.customitemqty	= storeproduct.customitemqty + 1;
			storeproduct.price			= storeproduct.baseprice + storeproduct.optiontotal;
	
			this.setState(
				()=>{
					return{
						products:[...tempProduct],
					};
				}
			);
		}
	}

	decrementCustomItem = (id) => {

		let tempProduct			= [...this.state.products];

		if(tempProduct !== undefined)
		{
			const selectedStoreProduct	= tempProduct.find(item=>item.id === id);

			const storeitemindex		= tempProduct.indexOf(selectedStoreProduct);
			const storeproduct			= tempProduct[storeitemindex];
	
			const checkqty				= storeproduct.customitemqty;

			if(checkqty > 1)
			{
				storeproduct.customitemqty	= storeproduct.customitemqty -1;
				storeproduct.price			= storeproduct.baseprice + storeproduct.optiontotal;
	
				this.setState(
					()=>{
						return{
							products:[...tempProduct],
						};
					}
				);
			}
			else
			{
				storeproduct.canuseoption		= false;
				storeproduct.canrepeatoption	= false;
	
				this.setState(()=>{
					return{
						products:[...tempProduct],
					};
				},()=>{
					document.body.style.overflow = "auto";
				});
			}
		}
	}

	closeconfirm = (status) => {

		this.setState(()=>{
			return{canplaceorder:false,isconfirmopen: status};
		});
	}

	operationtimeago =()=>{
		var lastordertime	= parseInt(localStorage.getItem("lastordertime"));
		var currDate = new Date();
		/*var diffMs	= currDate.getTime() - new Date(stringDate).getTime();*/
		var diffMs	= currDate.getTime() - lastordertime;
		var sec=diffMs/1000;
		if(sec<60)
			return parseInt(sec)+' second'+(parseInt(sec)>1?'s':'')+' ago';
		var min=sec/60;
		if(min<60)
			return parseInt(min)+' minute'+(parseInt(min)>1?'s':'')+' ago';
		var h=min/60;
		if(h<24)
			return parseInt(h)+' hour'+(parseInt(h)>1?'s':'')+' ago';
		var d=h/24;
		if(d<30)
			return parseInt(d)+' day'+(parseInt(d)>1?'s':'')+' ago';
		var m=d/30;
		if(m<12)
			return parseInt(m)+' month'+(parseInt(m)>1?'s':'')+' ago';
		var y=m/12;
		return parseInt(y)+' year'+(parseInt(y)>1?'s':'')+' ago';
	}

	setGuestUser = () => {

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

		if(tabledetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'AddGuestUser');
				bodyFormData.set('eventid', tabledetail.eventid);
				bodyFormData.set('orloid', tabledetail.code);
				bodyFormData.set('tracktablet', Number(tabledetail.tracktablet));
	
				axios({
				  method: 'post',
				  url: `${process.env.REACT_APP_API_URL}applogin.php?t=${Date.now()}`,
				  data: bodyFormData,
				  config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					if(response.data.success === true)
					{
						localStorage.setItem("guestid",response.data.guestid);
					}
					else
					{
						console.log("unable to create guest user");
					}
				})
				.catch(function (response){
					//handle error
					console.log(response);
				});
			}
		}
	}

	checkDuplicateOrder = async () => {

		let tempisduplicateorder	= true;

		var lastordertime	= parseInt(localStorage.getItem("lastordertime"));
		var currDate		= new Date();
		var diffMs			= currDate.getTime() - lastordertime;
		var sec				= diffMs/1000;

		if(sec < 5)
		{
			const cartdetails		= await this.state.db.fetchAllCartItem();
			const lastorderdetails	= await this.state.db.fetchAllLastOrderItem();
	
			if(lastorderdetails.length === cartdetails.length)
			{
				cartdetails.forEach(item => {
					const singleCartItem = {...item};
	
					let ordereditemdetail	= lastorderdetails.find(ordereditem => ordereditem.id === singleCartItem.id);
	
					if(ordereditemdetail)
					{
						if((ordereditemdetail.id === singleCartItem.id) && (ordereditemdetail.count === singleCartItem.count))
						{
						}
						else
						{
							tempisduplicateorder = false;
						}
					}
				});
			}
			else
			{
				tempisduplicateorder = false;
			}
		}
		else
		{
			this.state.db.deleteAllLastCartItem();
			tempisduplicateorder = false;
		}

		this.setState({isduplicateorder:tempisduplicateorder});	
	}

	setAgreeForDuplicateOrder=()=>{

		this.setState(()=>{
			return{
				canplaceorder:true
			};
		});
	}

	syncCategoryCount = () =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'SyncCategoryCount');
		bodyFormData.set('eventid', eventid);

        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState({
					activecount:response.data.detail.activecount,
					inactivecount:response.data.detail.inactivecount,
				})
			}
			else
			{
				this.setState({
					activecount:0,
					inactivecount:0,
				})
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	syncStewardDetail = () =>{

		let eventid			= "";
		let stewardid		= "";
	
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
		{
			eventid		= stewarddetail.eventid;
			stewardid	= stewarddetail.stewardid;
		}

        if(this.state.isdefaultevent)
        {
          var tempArr 	= this.state.defaulteventfile.split("_");
          var tempArr2	= tempArr[1].split(".");
    
          eventid	= Number(tempArr2[0]);
        }

		const{loggedintabledetail}	= this.state;

		if(loggedintabledetail)
		{
			eventid	= loggedintabledetail.eventid;
		}

		if(eventid < 1 || stewardid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'SyncStewardDetail');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('stewardid', stewardid);

        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}steward.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				let temploggedintabledetail	= {...response.data.detail, latestupdatedtime:stewarddetail.latestupdatedtime, isdefault:stewarddetail.isdefault, isdemoevent:stewarddetail.isdemoevent, eventdatafile:stewarddetail.eventdatafile, democode:stewarddetail.democode, showitemimages:stewarddetail.showitemimages, showbanners:stewarddetail.showbanners, selectedtable:stewarddetail.selectedtable};
				localStorage.setItem("stewarddetail",JSON.stringify(temploggedintabledetail));
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	RetryPlaceOrder=()=>{

		this.setState(()=>{
			return{
				placingorder:false,
				isordererror:false
			}
		},()=>{
			this.placeOrder();
		});

	}

    placeOrder = () => {

		if(!this.state.miscdetail.servicestatus)
		{
			return;
		}

		if(!this.state.isdefaultevent)
		{
			this.toastMessage(`Opps! Unable to process your order since service for the event has been closed.`, 'error');
			return false;
		}

		let loggedininfo  = {};

		if(this.state.canretryorder === true)
		{
			setTimeout(()=>{
				if(this.state.placingorder === true)
				{
				   this.setState({
					   isordererror:true
				   });
				}
		   },5000);
		}

		if(this.state.orderingtime < 1)
		{
			this.setState({orderingtime:Date.now()});
		}

		if(this.state.placingorder === false)
		{
			this.setState(()=>{

				return{
					placingorder: true,
					showhaveyloadmessage: false
				};
				
			},()=>{

				let guestid	= localStorage.getItem('guestid');

				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'Checkout');

				if(this.state.iscaptionlogin !== true && this.state.isstewardlogin !== true)
				{
					let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

					bodyFormData.set('tablename', tabledetail.title);
					bodyFormData.set('tableno', tabledetail.code);
					bodyFormData.set('isvip', Number(tabledetail.isviptable));
					bodyFormData.set('iscaptionorder', 0);
					bodyFormData.set('seatid', tabledetail.seatid);
				}
				else if(this.state.iscaptionlogin === true)
				{
					bodyFormData.set('iscaptionorder', 1);
					bodyFormData.set('ordertableid', this.state.ordertableid);
					bodyFormData.set('ordertablename', this.state.ordertablename);
					bodyFormData.set('ordertablecode', this.state.ordertablecode);
					bodyFormData.set('ordertablecaptionid', this.state.ordertablecaptionid);
					bodyFormData.set('ordertableloginid', this.state.ordertablerunnerid);
					bodyFormData.set('seatid', this.state.ordertableseatid);
					bodyFormData.set('isvip', Number(this.state.isvipchecked));
				}
				else if(this.state.isstewardlogin === true)
				{
					bodyFormData.set('isstewardorder', 1);
					bodyFormData.set('ordertableid', this.state.ordertableid);
					bodyFormData.set('ordertablename', this.state.ordertablename);
					bodyFormData.set('ordertablecode', this.state.ordertablecode);
					bodyFormData.set('ordertableseatid', this.state.ordertableseatid);
					bodyFormData.set('stewardid', this.state.stewardid);
					bodyFormData.set('stewardtabletype', this.state.stewardtabletype);
					bodyFormData.set('stewardname', this.state.stewardname);
					bodyFormData.set('isvip', Number(this.state.isvipchecked));
				}

				let eventname		= this.state.eventdetail.eventname;
				let eventdate		= this.state.eventdetail.eventdateunix;
				let order_gap_time  = this.state.eventdetail.throttle_order_gap;
		
				let canprocess		= true;

				bodyFormData.set('eventname', eventname);
				bodyFormData.set('eventdate', eventdate);
				bodyFormData.set('guestid', guestid);
				bodyFormData.set('order_gap_time', order_gap_time);
				bodyFormData.set('isloggedin', this.state.isloggedin);
				bodyFormData.set('orderingtime', this.state.orderingtime);
				bodyFormData.set('orderremark', this.state.orderremark);

				let cartdata	= [...this.state.cart];

				let cartNum		= Object.keys(cartdata).length;

				bodyFormData.set('total', cartNum);

				bodyFormData.set('cartitem', JSON.stringify(cartdata));

				let eventid		= 0;

				let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
				let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
				let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

				if(tabledetail || captiondetail || stewarddetail)
				{
					if(Number(tabledetail.eventid) > 0)
					{
						eventid	= tabledetail.eventid;
					}
					else if(Number(stewarddetail.eventid) > 0)
					{
						eventid	= stewarddetail.eventid;

						loggedininfo	= stewarddetail;
					}
					else if(Number(captiondetail.eventid) > 0)
					{
						eventid	= captiondetail.eventid;

						loggedininfo	= captiondetail;
					}

					if(this.state.isdefaultevent)
					{
						var tempArr 	= this.state.defaulteventfile.split("_");
						var tempArr2	= tempArr[1].split(".");

						eventid			= tempArr2[0];
					}
				}

				const{loggedintabledetail}	= this.state;

				if(loggedintabledetail)
				{
					eventid	= loggedintabledetail.eventid;
				}

				if(eventid > 0)
				{
					bodyFormData.set('event_id', eventid);
				}

				if(canprocess === true)
				{
					axios({
						method: 'post',
						url: `${process.env.REACT_APP_API_URL}checkout.php?t=${Date.now()}`,
						data: bodyFormData,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
					})
					.then(response=>{

						this.setState({placingorder:false});

						if(response.data.success === true)
						{
							const {ordertableid, ordertablename, ordertablecode, ordertableseatid} = this.state;

							let tempRecentTableList		= [];

							if(loggedininfo.recenttablelist && loggedininfo.recenttablelist !== null && loggedininfo.recenttablelist !== undefined)
							{
								loggedininfo.recenttablelist.forEach(item => {
									const singleTable = {...item};
					
									tempRecentTableList = [...tempRecentTableList, singleTable];
								});
							}

							tempRecentTableList	= tempRecentTableList.filter(temptable => Number(temptable.ordertableid) !== Number(ordertableid));

							tempRecentTableList	= [...tempRecentTableList, {ordertableid:ordertableid, ordertablename:ordertablename, ordertablecode:ordertablecode, ordertableseatid:ordertableseatid}];

							tempRecentTableList.sort((a, b) => a.ordertableid > b.ordertableid ? 1 : -1);

							loggedininfo  = {...loggedininfo, ordertableid:ordertableid, ordertablename:ordertablename, ordertablecode:ordertablecode, ordertableseatid:ordertableseatid, recenttablelist:tempRecentTableList};

							if(Number(stewarddetail.eventid) > 0)
							{
								localStorage.setItem("stewarddetail",JSON.stringify(loggedininfo));
								this.setState({tableid:ordertableid});
							}
							else if(Number(captiondetail.eventid) > 0)
							{
								localStorage.setItem("captiondetail",JSON.stringify(loggedininfo));
								this.setState({tableid:ordertableid});
							}

							localStorage.setItem("lastordertime",Date.now());

							if(response.data.orlo_order_id > 0)
							{
								let storeProducts	= this.state.products;
								let tempProducts	= [];
								
								let item;
								for(item in storeProducts)
								{
									let singleItem		= storeProducts[item];

									singleItem	= {...singleItem, count:0, total:0, inCart:false};

									tempProducts = [...tempProducts, singleItem];
								}

								this.setState(()=>{
									return{
										isorderadded: true,
										orlo_order_id:response.data.orlo_order_id,
										isvipchecked:false,
										orderingtime:0,
										isordererror:false,
										isorderprocessdone:true,
										orderitemdetails:[],
										pendingqueue:'',
										waitingtime:'',
										pendingitemqty:0,
										haspendingitem:true,
										showpendingitem:false,
										cancancelitem:false,
										isdetailfetched:true,
										istableopen:false,
										products: tempProducts,
										cart:[],
										showsearch:false,
										searchkeyword:'',
										orderremark:'',
									};
								},()=>{

									this.clearCart();
									this.checkEngageRunner();
								});
							}
							else
							{
								this.setState(()=>{
									return{
										isorderadded: false,
										orlo_order_id:'',
										isordererror:true,
										isorderprocessdone:true,
										orderingtime:0,
									};
								});
							}
						}
						else
						{
							this.setState(()=>{
								return{
									isorderadded: false,
									orlo_order_id:'',
									isordererror:true,
									isorderprocessdone:true,
									orderingtime:0
								};
							},()=>{
								this.toastMessage(response.data.msg, 'error');
							});
						}
					})
					.catch(function (response) {
						//handle error
						console.log(response);
					});
				}
				else
				{
					this.toastMessage(`Please enter in stock item to place your order`, 'error');
				}
			});
		}
	}

	ResetOrderProcess = () =>{
		this.setState({
			isorderadded:false,
			orlo_order_id:'',
			isreorderadded:false,
			re_orlo_order_id:''
		});
	}

	removeoutofstockitem = async() =>{
		const cartdetails		= await this.state.db.fetchAllCartItem();

		cartdetails.forEach(item => {

			const singleCartItem	= {...item};
			let itemid				= singleCartItem.id;
			let productdetail		= this.getItem(itemid);
			const instockitem		= productdetail.instock;

			if(instockitem === false)
			{
				this.removeItem(itemid);
			}
		});
	}

	resetDuplicateState=()=>{
		this.setState({isduplicateorder:false});
	}

	showError=(element, message)=>{
		if(document.getElementsByClassName(element).length > 0)
		{
		  document.getElementsByClassName(element)[0].innerText = message;
		}

		setTimeout(() => {
		  if(document.getElementsByClassName(element).length > 0)
		  {
			document.getElementsByClassName(element)[0].innerText = '';
		  }
		}, 4800);
	}

	showHideEventSeat=(status)=>{
		this.setState({
			showeventseat:status
		})
	}

	setOrderTableAndAddOrder=(tableid,tabletitle,tablecode,seatid)=>{

		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		let ordertablecaptionid	= "";
		let ordertablerunnerid	= "";
		let stewardid			= "";
		let stewardtabletype	= "";
		let stewardname			= "";
		let canaddorder			= false;

		if(Number(captiondetail.eventid) > 0)
		{
			canaddorder	= true;
			ordertablecaptionid	= captiondetail.id;
			ordertablerunnerid	= captiondetail.runnerid;
		}
		else if(Number(stewarddetail.eventid) > 0)
		{
			canaddorder	= true;
			stewardid			= stewarddetail.stewardid;
			stewardtabletype	= stewarddetail.tabletype;
			stewardname			= stewarddetail.name;
		}

		if(!canaddorder)
		{
			return false;
		}

		this.setState(()=>{
			return{
				ordertableid:tableid,
				ordertablename:tabletitle,
				ordertablecode:tablecode,
				ordertableseatid:seatid,
				ordertablecaptionid:ordertablecaptionid,
				ordertablerunnerid:ordertablerunnerid,
				stewardid:stewardid,
				stewardtabletype:stewardtabletype,
				stewardname:stewardname,
			};
		},()=>{			
			this.placeOrder();
		});
	}

	setOrderTableAndAddOrderByTableSelection=()=>{

		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		let ordertablecaptionid	= "";
		let ordertablerunnerid	= "";
		let stewardid			= "";
		let stewardtabletype	= "";
		let stewardname			= "";

		let canaddorder			= false;

		let loggedininfo		= {};

		if(Number(captiondetail.eventid) > 0)
		{
			canaddorder	= true;
			ordertablecaptionid	= captiondetail.id;
			ordertablerunnerid	= captiondetail.runnerid;

			loggedininfo		= captiondetail.selectedtable;
		}
		else if(Number(stewarddetail.eventid) > 0)
		{
			canaddorder	= true;
			stewardid			= stewarddetail.stewardid;
			stewardtabletype	= stewarddetail.tabletype;
			stewardname			= stewarddetail.name;

			loggedininfo		= stewarddetail.selectedtable;
		}

		if(!canaddorder)
		{
			return false;
		}

		this.setState(()=>{
			return{
				ordertableid:loggedininfo.id,
				ordertablename:loggedininfo.title,
				ordertablecode:loggedininfo.code,
				ordertableseatid:loggedininfo.seatid,
				isvipchecked:Number(loggedininfo.isvip),
				ordertablecaptionid:ordertablecaptionid,
				ordertablerunnerid:ordertablerunnerid,
				stewardid:stewardid,
				stewardtabletype:stewardtabletype,
				stewardname:stewardname,
			};
		},()=>{
			if(this.state.miscdetail.isremarkavailable || this.state.miscdetail.isremarkavailablefortable)
			{
				this.toggleOrderRemark();
			}
			else
			{
				this.placeOrder();
			}
		});
	}

	handleMarkVipOrder=()=> {
		this.setState({isvipchecked: !this.state.isvipchecked});
	}

	GetEngagedRunners = (e) => {

		let eventid			= "";

		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'FetchAllEngagedRunner');
		bodyFormData.set('eventid', eventid);
  
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			if(response.data.success === true)
			{
				let tempRunners	= [];

				response.data.result.forEach(runner => {
					
					let canshow		= false;

					const singleRunner = {...runner};

					if(this.state.searchrunner === true && (this.state.searchRunnerKeyword !== undefined && this.state.searchRunnerKeyword !== ""))
					{
						if(singleRunner.runnerid === this.state.searchRunnerKeyword)
						{
							canshow	= true;
						}
					}
					else{
						canshow	= true;
					}

					if(canshow === true)
					{
						tempRunners = [...tempRunners, singleRunner];
					}
				});

				this.setState({
					engagedrunners:tempRunners,
					allengagedrunners:response.data.result,
					upcommingrunners:response.data.upcommingrunners,
					runnersystemrequired:response.data.runnersystemrequired,
				},()=>{
					this.applySearchRunner();
				});
			}
			else
			{
				this.setState({
					engagedrunners:[],
					allengagedrunners:[],
					runnersystemrequired:response.data.runnersystemrequired,
				},()=>{
					this.applySearchRunner();
				});
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	releaseRunnerByID=(id,name)=>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'ReleaseRunner');
		bodyFormData.set('runnerid', id);
		bodyFormData.set('eventid', eventid);

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			this.GetEngagedRunners();

			if(response.data.success === true)
			{
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	viewDetail=(runnerid,tokenno,showpopup)=>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let canshowpopup	= false;

		if(showpopup === true)
		{
			canshowpopup	= !this.state.isActiveDetail;
		}

        this.setState(()=>{
            return{
                isActiveDetail: canshowpopup
            }
        },()=>{
            if(this.state.isActiveDetail === true || showpopup === false)
            {
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'ViewTokenOrderDetail');
				bodyFormData.set('runnerid', runnerid);
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('tokenno', tokenno);
		
				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					
					if(response.data.success === true)
					{
						this.setState({
							tokenitemdetails:response.data.orderdetails,
							tokenitemummary:response.data.tokenitemummary
						});
					}
					else
					{
						this.setState(()=>{
							return{
								isActiveDetail:false,
								isreleaserunnerpopup:false,
								tokenitemdetails:[],
								tokenitemummary:[]
							};
						},()=>{
							this.toastMessage(`No item found to view.`, 'error');
						})
					}
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
            }
        });
	}

	applySearchRunner = () => {

		let tempRunners	= [];

		if(this.state.searchrunnerid !== undefined && this.state.searchrunnerid !== null && this.state.searchrunnerid !== '')
		{
			this.state.allengagedrunners.forEach(runner => {
				const singleRunner = {...runner};

				if(singleRunner.runnerid === this.state.searchrunnerid)
				{
					tempRunners = [...tempRunners, singleRunner];
				}
			});

			this.setState({
				engagedrunners:tempRunners
			});
		}
		else
		{			
			this.setState({
				engagedrunners:this.state.allengagedrunners
			});
		}
	}

	getOrderedItemDetail=()=>{

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'FetchAllOrderedTableItem');		

		let eventid			= "";
		let code			= "";
		let istablelogin	= false;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail || this.state.isdefaultevent === true)
		{
			if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
			{
				eventid	= tabledetail.eventid;
				if(Number(tabledetail.code) > 0)
				{
					code	= Number(tabledetail.code);
				}
				bodyFormData.set('tablename', tabledetail.title);
				istablelogin	= true;
			}
			else if(captiondetail !== null && (Number(captiondetail.eventid) > 0  && captiondetail.eventid !== null))
			{
				eventid	= captiondetail.eventid;
				if(Number(this.state.tableid) > 0)
				{
					code	= Number(this.state.tableid);
				}
				bodyFormData.set('iscaptionlogin', Number(this.state.iscaptionlogin));
			}
			else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
			{
				eventid	= stewarddetail.eventid;
				if(Number(this.state.tableid) > 0)
				{
					code	= Number(this.state.tableid);
				}
				bodyFormData.set('isstewardlogin', Number(this.state.isstewardlogin));
			}

			if(this.state.isdefaultevent)
			{
				var tempArr 	= this.state.defaulteventfile.split("_");
				var tempArr2	= tempArr[1].split(".");

				eventid			= tempArr2[0];
			}
		}

		const{loggedintabledetail}	= this.state;

		if(loggedintabledetail)
		{
			eventid	= loggedintabledetail.eventid;
		}

		if(code > 0)
		{
			this.setState({istableselected:true,isordereditemloaded:this.state.isordereditemloaded});
		}
		else
		{
			this.setState({istableselected:false,isordereditemloaded:this.state.isordereditemloaded});
		}

		if(eventid < 1)
		{
			return false;
		}

		bodyFormData.set('eventid', eventid);
		bodyFormData.set('tableno', code);
		bodyFormData.set('istablelogin', Number(istablelogin));

		setTimeout(() => {

			axios({
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
				data: bodyFormData,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
			})
			.then(response=>{
	
				if(response.data.success === true)
				{
					this.setState({
						orderitemdetails:response.data.itemdetails,
						pendingqueue:response.data.pendingqueue,
						waitingtime:response.data.waitingtime,
						pendingitemqty:response.data.pendingitemqty,
						haspendingitem:true,
						showpendingitem:response.data.showpendingitem,
						cancancelitem:response.data.cancancelitem,
						engagedtableinfo:response.data.engagedtableinfo,
						isdetailfetched:true,
						isordereditemloaded:true,
					},()=>{
	
						const local_isitemmarkdeliveredbyguest = localStorage.getItem('isitemmarkdeliveredbyguest');
	
						if((local_isitemmarkdeliveredbyguest || local_isitemmarkdeliveredbyguest === null) && !response.data.isitemmarkdeliveredbyguest)
						{
							let recentdeliverrunnerid	= response.data.recentdeliverrunnerid;
							let isitemmarkdeliveredbyguest	= response.data.isitemmarkdeliveredbyguest;
							if(tabledetail)
							{
								if(!tabledetail.ordernotification)
								{
									recentdeliverrunnerid		= '';
									isitemmarkdeliveredbyguest	= true;
								}
							}
							this.setState(()=>{
								return{
									isitemmarkdeliveredbyguest:isitemmarkdeliveredbyguest,
									recentdeliverrunnerid:recentdeliverrunnerid,
								}
							},()=>{
								localStorage.setItem("isitemmarkdeliveredbyguest",isitemmarkdeliveredbyguest);
								
								setTimeout(() => {
									
									this.markOrderHasDeliveredByRunner();

								}, 180000);
							})
						}
					});
				}
				else{
					this.setState({
						orderitemdetails:[],
						pendingqueue:'',
						waitingtime:'',
						pendingitemqty:0,
						haspendingitem:false,
						showpendingitem:false,
						cancancelitem:false,
						engagedtableinfo:response.data.engagedtableinfo,
						isdetailfetched:true,
						isitemmarkdeliveredbyguest:true,
						recentdeliverrunnerid:'',
						isordereditemloaded:true,
					});
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
			
		}, 1500);
	}

	handleTableChange = (e) =>{

		const name	= e.target.name;
		const value	= e.target.value;

		let tempistableselected	= false;

		if(Number(value) > 0)
		{
			tempistableselected	= true;
		}

		this.setState(()=>{
			return{
				[name]:value,
				isordereditemloaded:false,
				istableselected:tempistableselected,
				orderitemdetails:[],
				pendingqueue:'',
				waitingtime:'',
				pendingitemqty:0,
				haspendingitem:false,
				showpendingitem:false,
				cancancelitem:false,
				isdetailfetched:true,
				isitemmarkdeliveredbyguest:true,
				recentdeliverrunnerid:'',
			}
		},()=>{
			this.getOrderedItemDetail();
		})
	}

	setIsdetailFetched=(status)=>{
		this.setState({
			isdetailfetched:status
		})
	}

	updateProductStatus = (id, activity) =>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateProductStatus');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('itemid', id);
		bodyFormData.set('activity', activity);

		let tempProducts	= [...this.state.allproducts];
		const index			= tempProducts.indexOf(this.getLiveItem(id));
		const product		= tempProducts[index];

		if(activity === "instock")
		{
			product.instock				= !product.instock;
			product.isinstockupdating	= true;

			bodyFormData.set('instock', product.instock);
		}
		else if(activity === "islivecounter")
		{
			product.islivecounter			= !product.islivecounter;
			product.islivecounterupdating	= true;

			bodyFormData.set('islivecounter', product.islivecounter);
		}
		else if(activity === "isselfservice")
		{
			product.isselfservice			= !product.isselfservice;
			product.isselfserviceupdating	= true;

			bodyFormData.set('isselfservice', product.isselfservice);
		}

		this.setState(()=>{
			return{
				allproducts:[...tempProducts]
			};
		},()=>{
			
			setTimeout(() => {

				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
	
					product.isinstockupdating		= false;
					product.islivecounterupdating	= false;
					product.isselfserviceupdating	= false;
					
					if(response.data.success !== true)
					{
						this.toastMessage(`Unable to process your request, Please try latter.`, 'error');
		
						if(activity === "instock")
						{
							product.instock	= !product.instock;
						}
						else if(activity === "islivecounter")
						{
							product.islivecounter	= !product.islivecounter;
						}
						else if(activity === "isselfservice")
						{
							product.isselfservice	= !product.isselfservice;
						}
					}
					else
					{
						this.toastMessage(response.data.msg, 'success');
					}
		
					this.setState(()=>{
						return{
							allproducts:[...tempProducts]
						};
					})
		
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
	
			}, 1000);
		})
	}

	GetEventMenu = (area) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetEventItemMenu');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('area', area);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}menu.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						alleventmenu:response.data.menus,
						menuid:response.data.menuid,
					};
				})
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	GetEventCategory = (area) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetEventItemCategory');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('area', area);
		if(area === 'addmenu')
		{
			bodyFormData.set('menuid', this.state.menuid);
		}
		else
		{
			bodyFormData.set('menuid', this.state.edititem_menuid);
		}

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				let tempcatid	= response.data.categoryid;

				if(tempcatid === "" || tempcatid === null || tempcatid === undefined || tempcatid < 1)
				{
					tempcatid	= this.state.categoryid;
				}

				this.setState(()=>{
					return{
						alleventcategory:response.data.categories,
						eventitemtypes:response.data.itemtypes,
						categoryid:tempcatid,
					};
				})
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	handleUserInput = (e) =>{
		const name	= e.target.name;
		const value	= e.target.value;

		if(name === "mobilenumber" && value.length > 10)
		{
			return false;
		}

		if(name === 'edititem_isoutofstock')
		{
			this.setState({
				edititem_isoutofstock:!this.state.edititem_isoutofstock
			})
		}
		else if(name === 'edititem_islivecounter')
		{
			this.setState({
				edititem_islivecounter:!this.state.edititem_islivecounter
			})			
		}
		else if(name === 'edititem_isselfservice')
		{
			this.setState({
				edititem_isselfservice:!this.state.edititem_isselfservice
			})			
		}
		else if(name === 'iscustomization')
		{
			this.setState({
				iscustomization:!this.state.iscustomization
			})
		}
		else
		{
			this.setState(()=>{
				return{
					[name]:value
				}
			},()=>{

				if(name === 'menuid')
				{
					if(this.state.menuid !== "")
					{
						this.GetEventCategory('addmenu');
					}
					else
					{
						this.setState({
							alleventcategory:[]
						})
					}
				}

				if(name === 'edititem_menuid')
				{
					if(this.state.edititem_menuid !== "")
					{
						this.GetEventCategory('editmenu');
					}
					else
					{
						this.setState({
							alleventcategory:[]
						})
					}
				}

				if(this.state.categoryid === "-1")
				{
					this.setState({
						isnewcategory:true
					});
				}
				else
				{
					this.setState({
						isnewcategory:false
					});
				}
				this.validateField(name, value);
			})
		}
	}

	validateField = (fieldname, value)=>{
		let validcategoryid			= this.state.validcategoryid;
		let validitemtypeid			= this.state.validitemtypeid;
		let validnewcategoryname	= this.state.validnewcategoryname;
		let validnewitemname		= this.state.validnewitemname;
		let validnewitemnamehindi	= this.state.validnewitemnamehindi;

		switch(fieldname) {
			case 'categoryid':
				validcategoryid = value.length >= 1;
			break;
			case 'itemtypeid':
				validitemtypeid = value.length >= 1;
			break;
			case 'newcategoryname':
				if(this.state.isnewcategory)
				{
					validnewcategoryname = value.length >= 1;
				}
				else
				{
					validnewcategoryname	= true;
				}
			break;
			case 'newitemname':
				validnewitemname = value.length >= 1;
			break;
			case 'newitemnamehindi':
				validnewitemnamehindi = value.length >= 1;
			break;
			default:
			break;
		}

		this.setState({
			validcategoryid: validcategoryid,
			validitemtypeid: validitemtypeid,
			validnewcategoryname: validnewcategoryname,
			validnewitemname:validnewitemname,
			validnewitemnamehindi:validnewitemnamehindi,
		},this.validateForm);
	}

	validateForm = () =>{

		let isvalidcategory	= false;
		let isvalidname		= false;

		if(this.state.categoryid === "-1")
		{
			if(this.state.isnewcategory === true && this.state.newcategoryname !== "")
			{
				isvalidcategory	= true;
			}
		}
		else if(this.state.categoryid > 0)
		{
			isvalidcategory	= true;
		}

		if(this.state.newitemname !== "")
		{
			isvalidname	= true;
		}

		this.setState({additemformvalid: this.state.validcategoryid && this.state.validitemtypeid && this.state.validnewcategoryname && this.state.validnewitemname && isvalidcategory && isvalidname});
	}

	handleRunnerAttendance = (e) =>{
		e.preventDefault();

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let errmsg	= "";

		if((this.state.runnerid === "" || this.state.runnerid === null || this.state.runnerid === undefined) && errmsg === "")
		{
			errmsg	= "Please enter runner id";
		}

		if((this.state.runnername === "" || this.state.runnername === null || this.state.runnername === undefined) && errmsg === "")
		{
			errmsg	= "Please enter name";
		}

		if((Number(this.state.tabletype) === 1 && Object.keys(this.state.alleventtable.filter(table => table.isselected === true)).length < 1) && errmsg === "")
		{
			errmsg = "Please select atleast one table to assign";
		}

		if(errmsg !== "")
		{
			this.toastMessage(errmsg, 'error');
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'RunnerAttendance');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('runnerrecordid', this.state.runnerrecordid);
		bodyFormData.set('runnerid', this.state.runnerid);
		bodyFormData.set('runnername', this.state.runnername);
		bodyFormData.set('mobilenumber', this.state.mobilenumber);
		bodyFormData.set('tabletype', this.state.tabletype);
		bodyFormData.set('alleventtable', JSON.stringify(this.state.alleventtable.filter(table => table.isselected === true)));

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						runnerrecordid:'',
						runnerid:'',
						runnername:'',
						mobilenumber:'',
					  };
				},()=>{
					document.querySelector('#batchrunnerid').focus();
					this.GetEventRunner();
					this.GetEventTable();
				})
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
				return false;
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	getSingleRunnerDetail=(runnerid)=>{

		let eventid	= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'GetRunnerDetail');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('runnerid', runnerid);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				const{recordid, runnerid, runnername, mobilenumber, tabletype, alleventtable} = response.data.detail;
				
				this.setState(()=>{
					return{
						runnerrecordid:recordid,
						runnerid:runnerid,
						runnername:runnername,
						mobilenumber:mobilenumber,
						tabletype:tabletype,
						alleventtable:alleventtable,
					};
				},()=>{
					this.toastMessage(`Please enter runner id to reassign order.`, 'success');
					document.querySelector('#batchrunnerid').focus();
				})
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
				return false;
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	handleIsFeaturedChange=(e)=>{

		this.setState(()=>{

			return{
				isfeaturedcategory:!this.state.isfeaturedcategory
			}

		});
		
	}

	handleIsActive=(e)=>{

		this.setState(()=>{

			return{
				categoryisactive:!this.state.categoryisactive
			}

		});

	}

	updateFeaturedStatus = (catid) =>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateFeaturedStatus');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('categoryid', catid);

		let tempCategory	= [...this.state.alleventcategory];
		const tempCategory2	= tempCategory.find(category => Number(category.id) === Number(catid));
		tempCategory2.isfeatured	= !tempCategory2.isfeatured;

		bodyFormData.set('isfeatured', tempCategory2.isfeatured);

        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success !== true)
            {
				this.toastMessage(`Unable to process your request, Please try latter`, 'error');
				tempCategory2.isfeatured	= !tempCategory2.isfeatured;
			}

			this.setState(()=>{
				return{
					alleventcategory:[...tempCategory]
				};
			})
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	updateCategoryStatus = (catid) =>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateCategoryStatus');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('categoryid', catid);

		let tempCategory	= [...this.state.alleventcategory];
		const tempCategory2	= tempCategory.find(category => Number(category.id) === Number(catid));
		tempCategory2.status	= !tempCategory2.status;

		bodyFormData.set('status', tempCategory2.status);

        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success !== true)
            {
				this.toastMessage(`Unable to process your request, Please try latter`, 'error');

				tempCategory2.status	= !tempCategory2.status;
			}

			this.setState(()=>{
				return{
					alleventcategory:[...tempCategory]
				};
			})

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	updateCategoryOrderBy = (e) =>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}
		
		const catid		= e.target.getAttribute('catid');
		const value		= e.target.value;

		let tempCategory	= [...this.state.alleventcategory];
		const tempCategory2	= tempCategory.find(category => Number(category.id) === Number(catid));
		tempCategory2.orderby	= value;

		this.setState(()=>{
			return{
				alleventcategory:[...tempCategory],
				iscategoyorderchange:true
			};
		})
	}

	saveCategoryOrderBy=(catid)=>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		if(this.state.iscategoyorderchange === true)
		{
			let tempCategory	= [...this.state.alleventcategory];
			const tempCategory2	= tempCategory.find(category => Number(category.id) === Number(catid));
	
			const bodyFormData = new FormData();
			bodyFormData.set('Mode', 'UpdateCategoryOrderBy');
			bodyFormData.set('eventid', eventid);
			bodyFormData.set('categoryid', catid);
			bodyFormData.set('orderby', tempCategory2.orderby);
	
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
				data: bodyFormData,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
			})
			.then(response=>{
				
				if(response.data.success !== true)
				{
					this.toastMessage(`Unbale to update category order, Please try latter`, 'error');
				}
				else
				{
					this.setState({iscategoyorderchange:false});
				}
	
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
		}
	}

	GetEventRunner = (area) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetEventRunner');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('area', area);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						alleventrunners:response.data.runners
					};
				})
			}
			else
			{
				this.setState(()=>{
					return{
						alleventrunners:[]
					};
				})
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	updateRunnerStatus = (runnerid) =>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateRunnerStatus');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('runnerid', runnerid);
		bodyFormData.set('type', 'suspend');

		let tempRunner		= [...this.state.alleventrunners];
		const tempRunner2	= tempRunner.find(runner => Number(runner.runnerid) === Number(runnerid));
		tempRunner2.issuspended	= !tempRunner2.issuspended;

		bodyFormData.set('issuspended', tempRunner2.issuspended);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success !== true)
            {
				this.toastMessage(`Unable to process your request, Please try latter`, 'error');

				tempRunner2.issuspended	= !tempRunner2.issuspended;
			}

			this.setState(()=>{
				return{
					alleventrunners:[...tempRunner]
				};
			})
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	viewEditMenuDetail=(recordid)=>{

		let eventid			= "";

		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let message = "";
		
        if(message === "")
        {
            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'GetMenuDetail');
            bodyFormData.set('eventid', eventid);
            bodyFormData.set('recordid', recordid);

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}menu.php?t=${Date.now()}`,
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{

                if(response.data.success === true)
                {
                    const{recordid, name, orderby, status} = response.data.detail;

                    this.setState(()=>{
                        return{
                            isedited:false,
                            recordid:recordid,
                            name:name,
                            orderby:orderby,
                            status:status,
                            modalstatus:{...this.state.modalstatus, editeventmenumodal:true},
                        };
                    })
                }
				else
				{
					this.toastMessage(`Unbale to fetch menu detail, Please try latter`, 'error');
				}
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        }
	}

	viewEditCategoryDetail_bak=(catid)=>{

		let eventid			= "";

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        this.setState(()=>{
            return{
                isActiveEditCategoryDetail: !this.state.isActiveEditCategoryDetail
            }
        },()=>{
            if(this.state.isActiveEditCategoryDetail === true)
            {
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'GetEditCategoryDetail');
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('categoryid', catid);

				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					
					if(response.data.success !== true)
					{
						this.toastMessage(`Unable to process your request, Please try latter`, 'error');
					}
					else
					{
						this.setState(()=>{
							return{
								editcategoryid:response.data.categorydetail.id,
								editcategorymenuid:response.data.categorydetail.menuid,
								newcategoryname:response.data.categorydetail.title,
								isfeaturedcategory:response.data.categorydetail.isfeatured,
								categoryisactive:response.data.categorydetail.status,
								categoryorderby:response.data.categorydetail.orderby,
								miscmenus:response.data.allmenus,
							};
						})
					}
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
            }
        });
	}

	viewEditCategoryDetail=(recordid)=>{
		let eventid			= "";

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let message = "";
		
        if(message === "")
        {
            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'GetCategoryDetail');
            bodyFormData.set('eventid', eventid);
            bodyFormData.set('recordid', recordid);

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{

                if(response.data.success === true)
                {
                    const{recordid, name, orderby, status, menuid} = response.data.detail;

                    this.setState(()=>{
                        return{
                            isedited:false,
                            recordid:recordid,
                            menuid:menuid,
                            name:name,
                            categoryname:name,
                            orderby:orderby,
                            status:status,
                            modalstatus:{...this.state.modalstatus, editcategorymodal:true},
                        };
                    },()=>{
						this.setKoteProducts(`editcategory`);
                    })
                }
                else
                {
                    this.showToastNotification(`Unbale to fetch category detail, Please try latter`,`danger`);
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        }
	}

	handleEditCategoryInput = (e) =>{
		const name	= e.target.name;
		const value	= e.target.value;

		this.setState(()=>{
			return{
				[name]:value
			}
		})
		//this.setState();
	}

	handleEditCategory_bak = (e) =>{
		e.preventDefault();

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'EditCategoryDetail');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('editcategorymenuid', this.state.editcategorymenuid);
		bodyFormData.set('categoryid', this.state.editcategoryid);
		bodyFormData.set('newcategoryname', this.state.newcategoryname);
		bodyFormData.set('isfeaturedcategory', this.state.isfeaturedcategory);
		bodyFormData.set('isactive', this.state.categoryisactive);
		bodyFormData.set('orderby', this.state.categoryorderby);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						editcategoryid:'',
						newcategoryname:'',
						isfeaturedcategory:false,
						categoryisactive:false,
						categoryorderby:false,
						isActiveEditCategoryDetail:false
					};
				},()=>{
					this.setKoteProducts(`editcategory`);
					this.toastMessage(`Category updated successfully`, 'success');
				})
			}
			else
			{
				this.syncCategoryCount();
				this.toastMessage(response.data.msg, 'error');
				return false;
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	handleEditCategory=(e)=> {
		e.preventDefault();
		
		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let message = "";

		if((this.state.menuid === "" || this.state.menuid === null || this.state.menuid === undefined) && message === "")
		{
			message = "Please select menu";
		}

		if((this.state.categoryname === "" || this.state.categoryname === null || this.state.categoryname === undefined) && message === "")
		{
			message = "Please enter name";
		}
		
        if(message !== "")
        {
			this.toastMessage(`${message}`, 'error');
        }

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'EditCategory');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('recordid', this.state.recordid);
					bodyFormData.set('menuid', this.state.menuid);
					bodyFormData.set('name', this.state.categoryname);
					bodyFormData.set('orderby', this.state.orderby);
					bodyFormData.set('status', this.state.status);

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false,
							message:'',
                        });
        
                        let color   = "error";
        
                        if(response.data.success === true)
                        {
							color   = "success";
							
							this.setState(()=>{
								return{
									isedited:true,
									recordid:'',
									name:'',
									orderby:'',
									categoryname:'',
									modalstatus:{...this.state.modalstatus, editcategorymodal:false},
								};
							},()=>{
								this.setKoteProducts(`editcategory`);
							})
                        }
						else
						{
							this.toastMessage(`${response.data.msg}`, `${color}`);
						}
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}

	cancelOrderItem = (id) =>{

		let eventid			= "";
		
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
		let stewarddetail   = JSON.parse(localStorage.getItem('stewarddetail'));

		let kotid	= 0;

		if(tabledetail || captiondetail || kotedetail || stewarddetail)
		{
			if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
			{
				eventid	= tabledetail.eventid;
			}
			else if(captiondetail !== null && (Number(captiondetail.eventid) > 0  && captiondetail.eventid !== null))
			{
				eventid	= captiondetail.eventid;
				kotid	= 1;
			}
			else if(kotedetail !== null && (Number(kotedetail.eventid) > 0 && kotedetail.eventid !== null))
			{
				eventid	= kotedetail.eventid;
				kotid	= 1;
			}
			else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
			{
				eventid	= stewarddetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}
		
		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'CancelOrderItem');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('kotid', kotid);
		bodyFormData.set('id', id);

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{
			
			if(response.data.success !== true)
			{
				this.toastMessage(`Unable to cancel item, please try latter`, 'error');
			}
			else
			{
				this.setState(()=>{
					return{
						isconfirmed:false,
						isdialogopen:false,
						confirmmessage:'',
						confirmrecordid:'',
						confirmrecordid2:'',
						cbfunctionname:'',
					}
				},()=>{
					this.getOrderedItemDetail();
				})				
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	cancelAllPendingItem = () =>{

		let eventid	= "";
		let code	= "";

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'CancelAllPendingOrderItem');

		let kotid	= 0;

		if(this.state.iscaptionlogin !== true && this.state.isstewardlogin !== true)
		{
			let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

			if(tabledetail)
			{
				if(Number(tabledetail.eventid) > 0)
				{
					eventid	= Number(tabledetail.eventid);
				}
				if(Number(tabledetail.code) > 0)
				{
					code	= Number(tabledetail.code);
				}
			}

			bodyFormData.set('tablename', tabledetail.title);
			bodyFormData.set('tableno', code);
			bodyFormData.set('eventid', eventid);
		}
		else if(this.state.iscaptionlogin === true)
		{
			let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

			if(captiondetail)
			{
				if(Number(captiondetail.eventid) > 0)
				{
					eventid	= Number(captiondetail.eventid);
				}
			}
			if(Number(this.state.tableid) > 0)
			{
				code	= Number(this.state.tableid);
			}

			kotid	= 1;

			bodyFormData.set('tableno', code);
			bodyFormData.set('eventid', eventid);
		}
		else if(this.state.isstewardlogin === true)
		{
			let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

			if(stewarddetail)
			{
				if(Number(stewarddetail.eventid) > 0)
				{
					eventid	= Number(stewarddetail.eventid);
				}
			}
			if(Number(this.state.tableid) > 0)
			{
				code	= Number(this.state.tableid);
			}

			kotid	= 1;

			bodyFormData.set('tableno', code);
			bodyFormData.set('eventid', eventid);
		}

		if(eventid < 1 || code < 1)
		{
			return false;
		}

		bodyFormData.set('kotid', kotid);

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			this.setState(()=>{
				return{
					isdialogopen:false,
					isconfirmed:false,
					confirmmessage:'',
					confirmrecordid:'',
					confirmrecordid2:'',
					cbfunctionname:'',
				}
			})

			if(response.data.success !== true)
			{
				this.toastMessage(`Unable to cancel item, please try latter`, 'error');
			}
			else
			{
				this.getOrderedItemDetail();
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	viewNotDeliveredItemDetail=(detailid, itemname, itemhindiname, qty, product_id, hasoption, itemoption)=>{

        this.setState(()=>{
            return{
				isactivenotdelivereditem: !this.state.isactivenotdelivereditem,
				notdelivereditemname:itemname,
				notdeliveredhindiitemname:itemhindiname,
				notdelivereditemqty:qty,
				notdelivereddetailid:detailid,
				notdeliveredproduct_id:product_id,
				notdeliveredhasoption:hasoption,
				notdelivereditemoption:itemoption,
            }
        });
	}

	handleSaveNotDeliveredItem=(detailid,itemqty,product_id,isplaceorder)=>{

		let eventid			= "";
		
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail)
		{
			if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
			{
				eventid	= tabledetail.eventid;
			}
			else if(captiondetail !== null && (Number(captiondetail.eventid) > 0 && captiondetail.eventid !== null))
			{
				eventid	= captiondetail.eventid;
			}
			else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
			{
				eventid	= stewarddetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'ComplainAndPlaceOrder');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('detailid', detailid);
		bodyFormData.set('product_id', product_id);
		bodyFormData.set('itemqty', itemqty);
		bodyFormData.set('isplaceorder', isplaceorder);
		bodyFormData.set('orderingtime', Date.now());

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{
			
			if(response.data.success !== true)
			{
				this.toastMessage(`Unable to process your request, Please try latter`, 'error');
			}
			else
			{
				this.setState(()=>{
					return{
						isactivenotdelivereditem: false,
						notdelivereditemname:"",
						notdeliveredhindiitemname:"",
						notdelivereditemqty:"",
						notdelivereddetailid:"",
						notdeliveredproduct_id:""
					};
				},()=>{
					this.getOrderedItemDetail();

					if(isplaceorder === true)
					{
						this.setState({
							isreorderadded: true,
							re_orlo_order_id:response.data.orlo_order_id,
							isreordererror:false,
							isreorderprocessdone:true,
						})
					}
				})
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}
	
	viewEditItemDetail=(itemid)=>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        this.setState(()=>{
            return{
                isActiveEditItemDetail: !this.state.isActiveEditItemDetail
            }
        },()=>{
            if(this.state.isActiveEditItemDetail === true)
            {
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'GetEditItemDetail');
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('itemid', itemid);

				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					
					if(response.data.success !== true)
					{
						this.toastMessage(`Unable to process your request, Please try latter`, 'error');
					}
					else
					{
						this.setState(()=>{
							return{
								edititem_id:Number(response.data.detail.id),
								edititem_menuid:Number(response.data.detail.menuid),
								edititem_categoryid:Number(response.data.detail.categoryid),
								edititem_itemtypeid:Number(response.data.detail.itemtypeid),
								edititem_itemname:response.data.detail.name,
								edititem_hindiname:response.data.detail.hindiname,
								edititem_islivecounter:Number(response.data.detail.islivecounter),
								edititem_isoutofstock:Number(response.data.detail.isoutofstock),
								edititem_isselfservice:Number(response.data.detail.isselfservice),
								edititem_zonenumber:response.data.detail.zonenumber,
								iscustomization:Number(response.data.detail.iscustomization),
								hasimage:response.data.detail.hasimage,
								itempreview:response.data.detail.itempreview,
							};
						},()=>{
							this.GetEventMenu('editmenu');
							this.GetEventCategory('editmenu');
						})
					}
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
            }
        });
	}

	handleEditItem = (e) =>{
		e.preventDefault();

		let message	= "";

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let filedata    = "";
		filedata = document.getElementById('imagefile').files[0];
		
		if((filedata !== "" && filedata !== undefined) && message === "")
		{
			let fileext = filedata.name.split('.').pop().toLowerCase();
			if(fileext !=='jpg' && fileext !=='jpeg')
			{
				message = "Please select a valid image";
			}
		}

		if(message !== "")
		{
			this.toastMessage(message, 'error');
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'EditItemDetail');
		bodyFormData.set('eventid', Number(eventid));
		bodyFormData.set('id', Number(this.state.edititem_id));
		bodyFormData.set('menuid', Number(this.state.edititem_menuid));
		bodyFormData.set('categoryid', Number(this.state.edititem_categoryid));
		bodyFormData.set('itemtypeid', Number(this.state.edititem_itemtypeid));
		bodyFormData.set('name', this.state.edititem_itemname);
		bodyFormData.set('hindiname', this.state.edititem_hindiname);
		bodyFormData.set('islivecounter', Number(this.state.edititem_islivecounter));
		bodyFormData.set('isoutofstock', Number(this.state.edititem_isoutofstock));
		bodyFormData.set('isselfservice', Number(this.state.edititem_isselfservice));
		bodyFormData.set('zonenumber', this.state.edititem_zonenumber);
		bodyFormData.set('iscustomization',Number(this.state.iscustomization));
		bodyFormData.set('customization', JSON.stringify(this.state.customization));
		bodyFormData.set('imagefile',filedata);
		bodyFormData.set('croppedimagefile',this.state.newimagedata);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						edititem_id:'',
						edititem_categoryid:'',
						edititem_itemtypeid:'',
						edititem_itemname:'',
						edititem_hindiname:'',
						edititem_islivecounter:'',
						edititem_isoutofstock:'',
						edititem_isselfservice:'',
						isActiveEditItemDetail:false,
						alleventcategory:[],
						eventitemtypes:[],
						hasimage:false,
						itempreview:"",
						hasgoogleimages:false,
						issearchingiamge:false,
						googleimages:[],
					  };
				},()=>{
					this.setKoteProducts();
					this.resetAttachedImage();
					this.toastMessage(response.data.msg, 'success');
				})
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
				return false;
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	handleAddBanner = (e) =>{
		e.preventDefault();

		const {bannerarea, bannertype}	= this.state;

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let message = "";
		
		let filedata	= "";
		let VideoFile	= "";
		let SmallImage	= "";

		if(Number(bannertype) === 1)
		{
			filedata		= document.getElementById('imagefile').files[0];
		}
		else
		{
			VideoFile		= document.getElementById('VideoFile').files[0];
			if(Number(bannerarea) === 1)
			{
				SmallImage		= document.getElementById('SmallImage').files[0];
			}
		}

		if(Number(bannertype) === 1)
		{
			if((filedata === "" || filedata ===undefined) && message === "")
			{
				message = "Please select an image to upload";
			}
			else if((filedata !== "" && filedata !== undefined) && message === "")
			{
				let fileext = filedata.name.split('.').pop().toLowerCase();
				if(fileext !=='jpg' && fileext !=='jpeg')
				{
					message = "Please select a valid image";
				}
			}
		}
		else
		{
			if(Number(bannerarea) === 1)
			{
				if((SmallImage !== "" && SmallImage !== undefined) && message === "")
				{
					let fileext = SmallImage.name.split('.').pop().toLowerCase();
					if(fileext !=='jpg' && fileext !=='jpeg')
					{
						message = "Please select a valid image";
					}
				}
			}
			if((VideoFile === "" || VideoFile === undefined) && message === "")
			{
				message = "Please select an video to upload";
			}
			else if((VideoFile !== "" && VideoFile !== undefined) && message === "")
			{
				let fileext = VideoFile.name.split('.').pop().toLowerCase();
				if(fileext !=='mp4')
				{
					message = "Please select a valid video";
				}
			}
		}
		
        if(message !== "")
        {
			this.toastMessage(message, 'error');
        }

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{
					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'AddEventBanner');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('imagefile',filedata);
					bodyFormData.set('orderby', Number(this.state.orderby));
					bodyFormData.set('status', Number(this.state.bannerstatus));
					bodyFormData.set('SmallImage',SmallImage);
					bodyFormData.set('VideoFile',VideoFile);
					bodyFormData.set('bannerarea', Number(bannerarea));
					bodyFormData.set('bannertype', Number(bannertype));

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}banners.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false
                        });
        
                        let color   = "error";
        
                        if(response.data.success === true)
                        {
							color   = "success";

							this.setState(()=>{
								return{
									orderby:'',
									bannerstatus:1,
								  };
							},()=>{
								
								if(Number(bannertype) < 1)
								{
									if(Number(bannerarea) === 1)
									{
										document.getElementById('SmallImage').value='';
									}
									document.getElementById('VideoFile').value='';
								}
								else
								{
									document.getElementById('imagefile').value='';
								}
								this.setEventBanner();
							})
                        }
						this.toastMessage(response.data.msg,color);
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }

	}

	handleAddMenu = (e) =>{
		e.preventDefault();

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		let message	= "";

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        if((this.state.menuid === undefined || this.state.menuid === null || this.state.menuid === "" || this.state.menuid === 0) && message === "")
        {
            message   = "Please select a menu.";
        }
        if((this.state.categoryid === undefined || this.state.categoryid === null || this.state.categoryid === "" || this.state.categoryid === 0) && message === "")
        {
            message   = "Please select a category.";
        }

        if(((this.state.newcategoryname === undefined || this.state.newcategoryname === null || this.state.newcategoryname === "") && this.state.categoryid === "-1") && message === "")
        {
            message   = "Please enter new category name.";
		}
		
        if((this.state.itemtypeid === undefined || this.state.itemtypeid === null || this.state.itemtypeid === "") && message === "")
        {
            message   = "Please select item type.";
		}

        if((this.state.newitemname === undefined || this.state.newitemname === null || this.state.newitemname === "") && message === "")
        {
            message   = "Please enter item name.";
		}

		let filedata    = "";
		filedata = document.getElementById('imagefile').files[0];
		
		if((filedata !== "" && filedata !== undefined) && message === "")
		{
			let fileext = filedata.name.split('.').pop().toLowerCase();
			if(fileext !=='jpg' && fileext !=='jpeg')
			{
				message = "Please select a valid image";
			}
		}

		if(message !== "")
		{
			this.toastMessage(message, 'error');
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'AddEventItem');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('menuid', this.state.menuid);
		bodyFormData.set('categoryid', this.state.categoryid);
		bodyFormData.set('itemtypeid', this.state.itemtypeid);
		bodyFormData.set('isnewcategory', this.state.isnewcategory);
		bodyFormData.set('newcategoryname', this.state.newcategoryname);
		bodyFormData.set('isfeaturedcategory', this.state.isfeaturedcategory);
		bodyFormData.set('newitemname', this.state.newitemname);
		bodyFormData.set('newitemhindiname', this.state.newitemnamehindi);
		bodyFormData.set('imagefile',filedata);
		bodyFormData.set('croppedimagefile',this.state.newimagedata);
		bodyFormData.set('zonenumber',this.state.zonenumber);
		bodyFormData.set('iscustomization',Number(this.state.iscustomization));
		bodyFormData.set('customization', JSON.stringify(this.state.customization));

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						isnewcategory:false,
						newcategoryname:'',
						newitemname:'',
						newitemnamehindi:'',
						zonenumber:'',
						additemformvalid:false,
						src: null,
						croppedImageUrl: null,
						newimagedata: null,
						hasgoogleimages:false,
						issearchingiamge:false,
						googleimages:[],
						iscustomization:false,
						customization:[{categoryindex:1,customizationname:'',customizationtype:2,orderby:'',customoption:[{optionindex:1,option:''}]}],
					};
				},()=>{
					this.toastMessage(response.data.msg, 'success');
					document.getElementById('imagefile').value = '';
					this.GetEventCategory();
				})
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
				return false;
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	GetEventInactiveCategory = (area) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetEventItemCategory');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('area', area);
		bodyFormData.set('status', Number(0));

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						alleventcategory:response.data.categories,
						eventitemtypes:response.data.itemtypes
					};
				},()=>{
					this.syncCategoryCount();
				})
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	markInactiveCategory = (catid) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateCategoryStatus');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('categoryid', catid);
		bodyFormData.set('status', Number(0));

        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setKoteProducts();
			}
			else
			{
				this.toastMessage(`Unable to process your request, Please try latter`, 'error');
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	applySearchProduct = (keyword) => {
		if(keyword !== undefined)
		{
			this.setState(()=>{
				return{
					showsearch: true,
					searchkeyword:keyword
				};
			},
			()=>{

				this.setProducts();
			});
		}
		else
		{
			this.setState(()=>{
				return{
					showsearch: false,
					searchkeyword:null
				};
			},
			()=>{

				this.setProducts();
			});
		}
	}

	applyCaptainSearchProduct = (keyword) => {
		if(keyword !== undefined)
		{
			this.setState(()=>{
				return{
					showsearch_captain: true,
					searchkeyword_captain:keyword
				};
			},
			()=>{

				this.setProducts();
			});
		}
		else
		{
			this.setState(()=>{
				return{
					showsearch_captain: false,
					searchkeyword_captain:''
				};
			},
			()=>{

				this.setProducts();
			});
		}
	}

	handleVegNonVeg=()=> {
        
		this.setState({

            isvegonly: !this.state.isvegonly,
            isProductLoaded: false

        },()=>{

			this.setProducts();
            
        });
	}

	handleNonVeg=()=> {
        
		this.setState({

            isnonveg: !this.state.isnonveg,
            isProductLoaded: false

        },()=>{

			this.setProducts();
            
        });
	}

	handleContainEgg=()=> {
        
		this.setState({
            containegg: !this.state.containegg,
            isProductLoaded: false
        },()=>{

			this.setProducts();
		});
	}

	showHideSearch=()=> {
        
		this.setState({
            showsearch: !this.state.showsearch
        },()=>{
			if(this.state.showsearch === false)
			{
				this.setState({
					searchkeyword:''
				},()=>{

					this.setProducts();
				})
			}
		});
	}

	handleChange=(e)=> {
        
        const name = e.target.name;
        const value = e.target.value;

		this.setState({
            [name]: value
        },()=>{
			if(this.state.showsearch === true && (name === 'searchkeyword'))
			{
				if(this.searchtimeout) clearTimeout(this.searchtimeout);
				this.searchtimeout = setTimeout(() => {
					
					this.setProducts();
	
				}, 300);
			}
			else if(name === 'searchrunnerid')
			{
				this.applySearchRunner();
			}
		});
	}

	toggleLargePreview = (id) =>{

		let tempProducts		= [...this.state.products];
		const index				= tempProducts.indexOf(this.getItem(id));
		const product			= tempProducts[index];
		product.islargepreview	= !product.islargepreview;

		let tempImagePreviewStatus	= [...this.state.imagepreviewstatus];

		const singlePreviewStatus			= tempImagePreviewStatus.find(singleitem=>Number(singleitem.id) === Number(id));
		singlePreviewStatus.islargepreview	= !singlePreviewStatus.islargepreview;

		this.setState(()=>{
			return {
				products:tempProducts,
				imagepreviewstatus:tempImagePreviewStatus,
			};
		})
	}

	toggleCartSummary=()=>{
        this.setState({
            iscartopen:!this.state.iscartopen
        },()=>{
			if(this.state.iscartopen)
			{
				document.body.style.overflow = "hidden";
			}
			else
			{
				document.body.style.overflow = "auto";
			}
		});
	}

	toggleListTable=()=>{
        this.setState({
            istableopen:!this.state.istableopen
        },()=>{
			if(this.state.istableopen)
			{
				document.body.style.overflow = "hidden";
				this.setEventTables();
			}
			else
			{
				document.body.style.overflow = "auto";
			}
		});
	}

	toggleOrderStatus=()=>{

        this.setState({
            isorderstatusopen:!this.state.isorderstatusopen,
            showitemforcomplain:false,
            recentdeliverrunnerid:'',
        },()=>{
			if(this.state.isorderstatusopen)
			{
				document.body.style.overflow = "hidden";
				this.getOrderedItemDetail();
			}
			else
			{
				document.body.style.overflow = "auto";
			}
		});
	}

    handleRunnerSearchFocus = (e) => {
		if(document.getElementsByClassName("_387x7").length > 0)
		{
			document.getElementsByClassName("_387x7")[0].style.visibility = 'hidden';
		}
    }

    handleRunnerSearchBlur = (e) => {
		if(document.getElementsByClassName("_387x7").length > 0)
		{
			document.getElementsByClassName("_387x7")[0].style.visibility = 'unset';
		}
    }

    handlecloseRunnerSearch = () => {
        this.setState(()=>{
            return{
				searchrunnerid: ''
			};
        },
        ()=>{
			this.applySearchRunner();
        });
    }

	releaseRunnerConfirmation=()=>{

        if(this.state.searchrunnerid === null || this.state.searchrunnerid === undefined || this.state.searchrunnerid === "")
        {
			this.toastMessage(`Please enter runner id to release`, 'error');
            document.getElementById("searchrunnerid").focus();
            return false;
        }
		else
		{
			this.toggleReleaseRunnerConfirmation();
		}
	}

	toggleReleaseRunnerConfirmation=()=>{

		this.setState(()=>{
			return{
				isreleaserunnerpopup:!this.state.isreleaserunnerpopup
			}
		},()=>{
			this.viewDetail(this.state.searchrunnerid, 0, false);
		})
	}

	releaseRunner=()=>{
		let eventid		= "";

        if(this.state.searchrunnerid === null || this.state.searchrunnerid === undefined || this.state.searchrunnerid === "")
        {
			this.toastMessage(`Please enter runner id to release`, 'error');
            document.getElementById("searchrunnerid").focus();
            return false;
        }

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		this.setState(()=>{
			return{
				releasingrunner:true
			}
		},()=>{
			setTimeout(()=>{
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'ReleaseRunner');
				bodyFormData.set('runnerid', this.state.searchrunnerid);
				bodyFormData.set('eventid', eventid);
		  
				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{

					this.setState(()=>{
						return{
							isreleaserunnerpopup:false,
							releasingrunner:false
						}
					},()=>{
						if(response.data.success === true)
						{
							this.setState({
								searchrunnerid:'',
							},()=>{
								this.GetEngagedRunners();
							})
						}
						else
						{
							this.toastMessage(response.data.msg, 'error');
						}
					})
					
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
			},1000)
		})
	}

	breakRunner=()=>{
        let eventid		= "";

        if(this.state.searchrunnerid === null || this.state.searchrunnerid === undefined || this.state.searchrunnerid === "")
        {
			this.toastMessage(`Please enter runner id to change status.`, 'error');
            document.getElementById("searchrunnerid").focus();
            return false;
        }
	  
        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
  
        if(tabledetail || captiondetail || kotedetail)
        {
            if(Number(tabledetail.eventid) > 0)
            {
                eventid	= tabledetail.eventid;
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                eventid	= captiondetail.eventid;
            }
            else if(Number(kotedetail.eventid) > 0)
            {
                eventid	= kotedetail.eventid;
            }
        }
  
        if(eventid < 1)
        {
          return false;
        }

		this.setState({
			isrunnerstatusprocessing:true
		})

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateRunnerStatus');
        bodyFormData.set('type', 'absent');
        bodyFormData.set('type2', 'release');
        bodyFormData.set('runnerid', this.state.searchrunnerid);
        bodyFormData.set('eventid', Number(eventid));
  
        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

			this.setState({
				isrunnerstatusprocessing:false
			})

			if(response.data.success === true)
			{
				this.setState({
					searchrunnerid:'',
					showbreakpopup:false,
				},()=>{
					this.GetEngagedRunners();
				});

				this.toastMessage(response.data.msg, 'success');
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	cancelRunnerOrder=()=>{

        if(this.state.searchrunnerid === null || this.state.searchrunnerid === undefined || this.state.searchrunnerid === "")
        {
			this.toastMessage(`Please enter runner id to change status`, 'error');
            document.getElementById("searchrunnerid").focus();
            return false;
        }

		if(!window.confirm("Are you sure? you want cancel order"))
		{
			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'CancelRunnerToken');
		bodyFormData.set('runnerid', this.state.searchrunnerid);
		
		let eventid		= "";
  
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
  
		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}
  
		if(eventid < 1)
		{
		  return false;
		}

		bodyFormData.set('kotid', '');
		bodyFormData.set('eventid', eventid);
  
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			this.setState(()=>{
				return{
					isdialogopen:false,
					isconfirmed:false,
					confirmmessage:'',
					confirmrecordid:'',
					confirmrecordid2:'',
					cbfunctionname:'',
				}
			})

			if(response.data.success === true)
			{
				this.setState({
					searchrunnerid:'',
				},()=>{
					this.GetEngagedRunners();
				})
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	cancelRunnerOrderByID=(id)=>{

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'CancelRunnerToken');
		bodyFormData.set('runnerid', id);
		
		let eventid			= "";
  
		let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
  
		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}
  
		if(eventid < 1)
		{
		  return false;
		}

		bodyFormData.set('kotid', '');
		bodyFormData.set('eventid', eventid);
  
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			this.setState(()=>{
				return{
					isdialogopen:false,
					isconfirmed:false,
					confirmmessage:'',
					confirmrecordid:'',
					confirmrecordid2:'',
					cbfunctionname:'',
				}
			})

			if(response.data.success === true)
			{
				this.GetEngagedRunners();
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	handleCancelRunnerOrderByCaptain=(runnerid, tokenno)=>{

      const bodyFormData = new FormData();
      bodyFormData.set('Mode', 'CancelRunnerToken');
      bodyFormData.set('runnerid', runnerid);
	  
	  let eventid			= "";
		
	  let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
	  let captiondetail		= JSON.parse(localStorage.getItem('captiondetail'));

	  if(tabledetail || captiondetail)
	  {
		  if(Number(tabledetail.eventid) > 0)
		  {
			  eventid	= tabledetail.eventid;
		  }
		  else if(Number(captiondetail.eventid) > 0)
		  {
			  eventid	= captiondetail.eventid;
		  }
	  }

	  if(eventid < 1)
	  {
		  return false;
	  }

      bodyFormData.set('kotid', '');
	  bodyFormData.set('eventid', eventid);

      axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}runner.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

		this.setState(()=>{
			return{
				isdialogopen:false,
				isconfirmed:false,
				confirmmessage:'',
				confirmrecordid:'',
				confirmrecordid2:'',
				cbfunctionname:'',
			}
		})

		if(response.data.success === true)
		{
			this.GetEngagedRunners();
			this.viewDetail(runnerid,tokenno, true);
		}
		else
		{
			this.toastMessage(response.data.msg, 'error');
		}
		})
		.catch(function (response) {
            //handle error
            console.log(response);
        });
	}
	
	playStopVideo=(selectedIndex)=>{

		let tempEventBanner	= [...this.state.eventbanner];

		const currentbanner	= tempEventBanner[selectedIndex];

		if(Number(currentbanner.type) === 0)
		{
			this.setState(()=>{
				return{
					autoplayvideo:true
				}
			})
		}
		else
		{
			this.setState(()=>{
				return{
					autoplayvideo:false
				}
			})
		}
	}

    listenToScroll = () => {

		const mybutton = document.getElementById("backtotop");

		if(mybutton !== null)
		{
			if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)
			{
				mybutton.style.display = "block";
			}
			else
			{
				mybutton.style.display = "none";
			}
	
			if(Object.keys(this.state.eventsmallbanner).length < 1)
			{
				return;
			}
	
			const element = document.querySelector('.mainwrapper');
	
			const position = element.getBoundingClientRect();        
	
			this.setState(()=>{
				return{
					theposition: position.top,
				}
			})
		}
    }

	handleShowHideBreak = () =>{
		this.setState({
			showbreakpopup:!this.state.showbreakpopup
		})
	}

	handleShowHideCancel = () =>{

		let eventid		= 0;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				eventid	= stewarddetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return;
		}

		if(!this.state.showcancelpopup)
		{
			if(this.state.searchrunnerid === null || this.state.searchrunnerid === undefined || this.state.searchrunnerid === "")
			{
				this.toastMessage(`Please enter runner id`, 'error');
				document.getElementById("searchrunnerid").focus();
				return false;
			}
		}
		this.setState({
			showcancelpopup:!this.state.showcancelpopup,
			runneritemdetail:[],
			catsummary:'',
		},()=>{

			if(this.state.showcancelpopup)
			{
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'FetchAllOrderedRunnerItem');
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('runnerid', this.state.searchrunnerid);
	
				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					if(response.data.success === true)
					{
						this.setState({
							runneritemdetail:response.data.itemdetails,
							catsummary:response.data.catsummary
						})
					}
					else
					{
						this.setState({
							showcancelpopup:false,
							runneritemdetail:[],
							catsummary:'',
						});
						this.toastMessage(`No item assigned to runner #${this.state.searchrunnerid}`, 'error');
					}
				})
				.catch(function (response){
					//handle error
					console.log(response);
				});
			}
		})
	}

	handleSelectDeselectItem=(id)=>{

        let temprunneritemdetail	= [...this.state.runneritemdetail];

        const tempitem     = temprunneritemdetail.find(singleitem => singleitem.detailid === id);

        tempitem.ischecked   = !tempitem.ischecked;

        this.setState(()=>{
          
          return{

            runneritemdetail: temprunneritemdetail

          }

        });
    }

	cancelSelectedItem=()=>{

        let temprunneritemdetail	= [...this.state.runneritemdetail];
		const tempitem				= temprunneritemdetail.filter(singleitem => singleitem.ischecked === true);

		let eventid		= 0;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				eventid	= stewarddetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return;
		}

		if(Object.keys(tempitem).length < 1)
		{
			this.toastMessage(`Please select atleast one item to cancel`, 'error');
			return;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'CancelAllSelectedItemByRunner');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('runnerid', this.state.searchrunnerid);
		bodyFormData.set('runneritemdetail', JSON.stringify(this.state.runneritemdetail));

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			this.setState(()=>{
				return{
					isdialogopen:false,
					isconfirmed:false,
					confirmmessage:'',
					confirmrecordid:'',
					confirmrecordid2:'',
					cbfunctionname:'',
				}
			})

			if(response.data.success === true)
			{
				this.setState({
					showcancelpopup:false,
					runneritemdetail:[]
				},()=>{
					this.toastMessage(response.data.msg, 'success');
				})
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
			}
		})
		.catch(function (response){
			//handle error
			console.log(response);
		});
	}

	selectdeselectitem=(type)=>{

		const{runneritemdetail} = this.state;

		let tempRunnerItems	= [];
		
		runneritemdetail.forEach(item => {
			const singleRunnerItem = {...item, ischecked:type};

			tempRunnerItems = [...tempRunnerItems, singleRunnerItem];
		});

		this.setState(()=>{
			return{
				runneritemdetail:tempRunnerItems
			}
		})
	}

	checkEngageRunner=()=>{
		let eventid			= 0;
		let istablelogin	= 0;
		let tableno			= 0;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
				istablelogin	= 1;
				tableno			= tabledetail.code;
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				eventid	= stewarddetail.eventid;
				istablelogin	= 0;
				tableno			= 0;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
				istablelogin	= 0;
				tableno			= 0;
			}
		}

		if(eventid < 1)
		{
			return;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'CheckAllEngageRunner');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('istablelogin', istablelogin);
		bodyFormData.set('tableno', tableno);

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			if(response.data.success === true)
			{
				this.setState({
					isallrunnerengage:response.data.isallrunnerengage,
					showoutfordeliverymessage:response.data.showoutfordeliverymessage,
					outfordeliverymessage:response.data.outfordeliverymessage,
				},()=>{
					
					let canshowoutfordeliverymsg	= false;

					if(this.state.showoutfordeliverymessage && !this.state.isoutfordeliveryshowing)
					{
						canshowoutfordeliverymsg	= true;
					}

					if(tabledetail)
					{
						if(!tabledetail.ordernotification)
						{
							canshowoutfordeliverymsg	= false;
						}
					}

					if(canshowoutfordeliverymsg)
					{
						this.setState({isoutfordeliveryshowing:true});

						toast.success(`${this.state.outfordeliverymessage}`, {
							position: "bottom-center",
							autoClose: 60000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							onClose:() => this.showHideOutforDeliveryMessage(),
						});
					}
				})
			}
			else
			{
				this.setState({
					isallrunnerengage:false,
					showoutfordeliverymessage:false,
					outfordeliverymessage:"",
				})
			}
		})
		.catch(function (response){
			//handle error
			console.log(response);
		});

	}

	checkRunnerStatusAndPlaceOrder=()=>{

		let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(this.state.isallrunnerengage)
		{
			this.setState(()=>{
				return{
					showhaveyloadmessage:true
				}
			})
		}
		else
		{
			let isremarkavailable = false;

			if(captiondetail|| stewarddetail)
			{
				if(((captiondetail !== null && Number(captiondetail.eventid) > 0) || (stewarddetail !== null && Number(stewarddetail.eventid) > 0)) && this.state.miscdetail.isremarkavailable)
				{
					isremarkavailable	= true;
				}
			}

			if((tabledetail !== null && Number(tabledetail.eventid) > 0) && this.state.miscdetail.isremarkavailablefortable)
			{
				isremarkavailable	= true;
			}

			if(tabledetail !== null && Number(tabledetail.eventid) > 0)
			{
				if(tabledetail.remarktype !== null)
				{
					if(tabledetail.remarktype === 'yes')
					{
						isremarkavailable	= true;
					}
					else if(tabledetail.remarktype === 'no')
					{
						isremarkavailable	= false;
					}
				}
			}

			if(isremarkavailable)
			{
				this.toggleOrderRemark();
			}
			else
			{
				this.placeOrder();
			}
		}
	}

	showHideHeavyLoadMessage=()=>{
		this.setState(()=>{
			return{
				showhaveyloadmessage:!this.state.showhaveyloadmessage
			}
		})
	}

	showHideOutforDeliveryMessage=()=>{
		this.setState(()=>{
			return{
				showoutfordeliverymessage:!this.state.showoutfordeliverymessage
			}
		},()=>{
			if(!this.state.showoutfordeliverymessage)
			{
				let eventid			= 0;
				let istablelogin	= 0;
				let tableno			= 0;
		
				let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
				let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
				let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));
		
				if(tabledetail || captiondetail || stewarddetail)
				{
					if(Number(tabledetail.eventid) > 0)
					{
						eventid	= tabledetail.eventid;
						istablelogin	= 1;
						tableno			= tabledetail.code;
					}
					else if(Number(stewarddetail.eventid) > 0)
					{
						eventid	= stewarddetail.eventid;
						istablelogin	= 0;
						tableno			= 0;
					}
					else if(Number(captiondetail.eventid) > 0)
					{
						eventid	= captiondetail.eventid;
						istablelogin	= 0;
						tableno			= 0;
					}
				}
		
				if(eventid < 1)
				{
					return;
				}
		
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'UpdateOutForDeliveryToHide');
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('istablelogin', istablelogin);
				bodyFormData.set('tableno', tableno);

				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
		
					if(response.data.success === true)
					{
						this.setState({
							outfordeliverymessage:"",
							isoutfordeliveryshowing:false,
						})
					}
					else
					{
						this.setState({
							outfordeliverymessage:"",
							isoutfordeliveryshowing:false,
						})
					}
				})
				.catch(function (response){
					//handle error
					console.log(response);
				});

			}
		})
	}

	handleShowHideSplitOrder = () =>{

		let eventid		= 0;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				eventid	= stewarddetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return;
		}

		if(!this.state.showsplitorderpopup)
		{
			if(this.state.searchrunnerid === null || this.state.searchrunnerid === undefined || this.state.searchrunnerid === "")
			{
				this.toastMessage(`Please enter runner id to split order`, 'error');
				document.getElementById("searchrunnerid").focus();
				return false;
			}
		}
		this.setState({
			showsplitorderpopup:!this.state.showsplitorderpopup,
			runneritemdetail:[],
			catsummary:'',
			totalitemavailable:0,
			availablerunner:0,
		},()=>{

			if(this.state.showsplitorderpopup)
			{
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'FetchAllOrderedRunnerItem');
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('runnerid', this.state.searchrunnerid);

				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					if(response.data.success === true)
					{
						this.setState({
							runneritemdetail:response.data.itemdetails,
							catsummary:response.data.catsummary,
							totalitemavailable:response.data.totalitemavailable,
							availablerunner:response.data.availablerunner,
						},()=>{
							if(this.state.totalitemavailable < 2 || this.state.availablerunner < 2)
							{
								this.setState({
									showsplitorderpopup:false,
									runneritemdetail:[],
									catsummary:'',
									totalitemavailable:0,
									availablerunner:0,
								});
								if(this.state.availablerunner < 2)
								{
									this.toastMessage(`No runner available to split order`, 'error');
								}
								else
								{
									this.toastMessage(`No need to split order because there is only 1 item assigned`, 'error');
								}
								document.getElementById("searchrunnerid").focus();
								return false;
							}
						})
					}
					else
					{
						this.setState({
							showsplitorderpopup:false,
							runneritemdetail:[],
							catsummary:'',
							totalitemavailable:0,
							availablerunner:0,
						});
						this.toastMessage(`No item assigned to runner #${this.state.searchrunnerid}`, 'error');
						document.getElementById("searchrunnerid").focus();
						return false;
					}
				})
				.catch(function (response){
					//handle error
					console.log(response);
				});
			}
		})
	}

	splitRunnerOrder=(isprint)=>{
        let eventid		= "";

		if(this.state.availablerunner < 2)
		{
			this.toastMessage(`No runner available to split order`, 'error');
            document.getElementById("numberofsplit").focus();
            return;
		}

        if(this.state.numberofsplit === null || this.state.numberofsplit === undefined || this.state.numberofsplit === "")
        {
			this.toastMessage(`Please enter number of split to split order`, 'error');
            document.getElementById("numberofsplit").focus();
            return;
        }

		if(Number(this.state.availablerunner) < Number(this.state.numberofsplit))
		{
			this.toastMessage(`${this.state.availablerunner} Runner available to split order`, 'error');
            document.getElementById("numberofsplit").focus();
            return;
		}

		if(Number(this.state.totalitemavailable) < Number(this.state.numberofsplit))
		{
			this.toastMessage(`Maximum ${this.state.totalitemavailable} split allowed. Please decrease split order`, 'error');
            document.getElementById("numberofsplit").focus();
			return;
		}

        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

        if(tabledetail || captiondetail || kotedetail)
        {
            if(Number(tabledetail.eventid) > 0)
            {
                eventid	= tabledetail.eventid;
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                eventid	= captiondetail.eventid;
            }
            else if(Number(kotedetail.eventid) > 0)
            {
                eventid	= kotedetail.eventid;
            }
        }

        if(eventid < 1)
        {
          return false;
        }

		this.setState({
			isrunnerstatusprocessing:true
		})

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'SplitRunnerOrder');
        bodyFormData.set('runnerid', this.state.searchrunnerid);
        bodyFormData.set('numberofsplit', this.state.numberofsplit);
        bodyFormData.set('eventid', Number(eventid));
        bodyFormData.set('isreprint', Number(isprint));

        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

			this.setState({
				isrunnerstatusprocessing:false
			})

			if(response.data.success === true)
			{
				this.setState({
					searchrunnerid:'',
					showsplitorderpopup:false,
					numberofsplit:2,
					catsummary:'',
					totalitemavailable:0,
					availablerunner:0,
				},()=>{
					this.GetEngagedRunners();
				});
				
				this.toastMessage(response.data.msg, 'success');
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	translateTextInHindi=(type)=>{

		if(type === 'add')
		{
			if(this.state.newitemname === "")
			{
				this.toastMessage(`Please enter item name to translate`, 'error');
				document.getElementById("newitemname").focus();
				return;
			}
		}
		else
		{
			if(this.state.edititem_itemname === "")
			{
				this.toastMessage(`Please enter item name to translate`, 'error');
				document.getElementById("edititem_itemname").focus();
				return;
			}
		}

        let eventid		= "";

        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

        if(tabledetail || captiondetail || kotedetail)
        {
            if(Number(tabledetail.eventid) > 0)
            {
                eventid	= tabledetail.eventid;
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                eventid	= captiondetail.eventid;
            }
            else if(Number(kotedetail.eventid) > 0)
            {
                eventid	= kotedetail.eventid;
            }
        }

        if(eventid < 1)
        {
          return;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'TranslateInHindi');
		if(type === 'add')
		{
			bodyFormData.set('englishtext', this.state.newitemname);
		}
		else
		{
			bodyFormData.set('englishtext', this.state.edititem_itemname);
		}

        axios({
            method: 'post',
			url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

			if(response.data.success === true)
			{
				this.setState({
					newitemnamehindi:response.data.hinditext,
					edititem_hindiname:response.data.hinditext,
				});
			}
			else
			{
				this.setState({
					newitemnamehindi:'',
					edititem_hindiname:'',
				});
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ src: reader.result, googleimages:[], hasgoogleimages:false })
        );
        reader.readAsDataURL(e.target.files[0]);
        }
    }

	onSelectGoogleFile = (imageres) => {
        if (imageres.path && imageres.path.length > 0) {

			this.setState({ src: imageres.blobdata });
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    }

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
        );
        this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);

				this.setState({
					newimagedata:canvas.toDataURL()
				})

				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			},'image/jpeg');
        });
    }

	handleMenuSelection=(menuid)=>{

		let tempMenu	= [...this.state.eventmenus];
		const tempMenu2	= tempMenu.find(menu => Number(menu.menuid) === Number(menuid));
		tempMenu2.ischecked	= !tempMenu2.ischecked;

		this.setState(()=>{
			return{
				eventmenus:[...tempMenu]
			};
		},()=>{
			
			let selectDeselectTitle	= 'Select All';

			if(Object.keys(this.state.eventmenus.filter(menu => menu.ischecked === true)).length === Object.keys(this.state.eventmenus).length)
			{
				selectDeselectTitle	= 'De-Select All';
			}

			this.setState({
				selectdeselecttitle:selectDeselectTitle
			})
		})
	}

	selectDeselectAllMenu=()=>{

		let type	= true;

		if(Object.keys(this.state.eventmenus.filter(menu => menu.ischecked === true)).length === Object.keys(this.state.eventmenus).length)
		{
			type	= false;
		}

		let tempEventMenus	= [];

		this.state.listeventmenus.forEach(menu => {
			const singleMenu = {...menu, ischecked:type};

			tempEventMenus = [...tempEventMenus, singleMenu];
		});

		this.setState(()=>{
			return{
				listeventmenus:tempEventMenus,
				eventmenus:tempEventMenus,
			};
		},()=>{
			let selectDeselectTitle	= 'Select All';

			if(Object.keys(this.state.listeventmenus.filter(menu => menu.ischecked === true)).length === Object.keys(this.state.listeventmenus).length)
			{
				selectDeselectTitle	= 'De-Select All';
			}

			this.setState({
				selectdeselecttitle:selectDeselectTitle
			})
		})
	}

	setLoggedOutGuestUser = () => {

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

		if(tabledetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'LoggedOutGuestUser');
				bodyFormData.set('eventid', tabledetail.eventid);
				bodyFormData.set('orloid', tabledetail.code);
	
				axios({
				  method: 'post',
				  url: `${process.env.REACT_APP_API_URL}applogin.php?t=${Date.now()}`,
				  data: bodyFormData,
				  config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					if(response.data.success !== true)
					{
						console.log("unable to logged out user");
					}
				})
				.catch(function (response){
					//handle error
					console.log(response);
				});
			}
		}
	}

	GetEventTable = () =>{

        let eventid		= "";

        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

        if(captiondetail || kotedetail)
        {
            if(Number(captiondetail.eventid) > 0)
            {
                eventid	= captiondetail.eventid;
            }
            else if(Number(kotedetail.eventid) > 0)
            {
                eventid	= kotedetail.eventid;
            }
        }

        if(eventid < 1)
        {
          return;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetEventTable');
		bodyFormData.set('eventid', eventid);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}steward.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
						alleventtable:response.data.recordlist
					};
				})
			}
			else
			{
				this.setState(()=>{
					return{
						alleventtable:[]
					};
				})
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	handleSelectDeselectTable=(id)=>{

        let tempalleventtable	= [...this.state.alleventtable];

        const temptable     = tempalleventtable.find(singletable => Number(singletable.id) === Number(id));

        temptable.isselected   = !temptable.isselected;

        this.setState(()=>{
          
          return{

            alleventtable: tempalleventtable

          }

        });
    }

	resetZoneNumber=()=>{
		this.setState({
			zonenumber:'',
			iscustomization:false,
			customization:[{categoryindex:1,customizationname:'',customizationtype:2,orderby:'',customoption:[{optionindex:1,option:''}]}]
		})
	}

	showEditZoneNumber = (id) =>{

		let tempProducts	= [...this.state.allproducts];
		const index			= tempProducts.indexOf(this.getLiveItem(id));
		const product		= tempProducts[index];

		product.iszoneediting	= !product.iszoneediting;

		this.setState(()=>{
			return{
				allproducts:[...tempProducts]
			};
		})
	}

	handleZoneNumberInput = (e, id) =>{

		const value		= e.target.value;

		let tempProducts	= [...this.state.allproducts];
		const index			= tempProducts.indexOf(this.getLiveItem(id));
		const product		= tempProducts[index];

		product.tempzonenumber	= value;

		this.setState(()=>{
			return{
				allproducts:[...tempProducts]
			};
		})
	}

	saveZoneNumber = (id) =>{

		let eventid			= "";
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let tempProducts	= [...this.state.allproducts];
		const index			= tempProducts.indexOf(this.getLiveItem(id));
		const product		= tempProducts[index];

		if(!product.iszoneupdating)
		{
			product.iszoneupdating	= true;

			this.setState(()=>{
				return{
					allproducts:tempProducts
				}
			},()=>{
				setTimeout(() => {

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'UpdateProductZoneNumber');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('itemid', id);
			
					bodyFormData.set('zonenumber', product.tempzonenumber);
			
					axios({
						method: 'post',
						url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
						data: bodyFormData,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
					})
					.then(response=>{
			
						product.iszoneediting	= false;
						product.iszoneupdating	= false;
						
						if(response.data.success !== true)
						{
							this.toastMessage(`Unable to process your request, Please try latter`, 'error');
			
							product.tempzonenumber	= product.zonenumber;
						}
						else
						{
							this.toastMessage(response.data.msg, 'success');
							product.zonenumber	= product.tempzonenumber;
						}
			
						this.setState(()=>{
							return{
								allproducts:[...tempProducts]
							};
						})
			
					})
					.catch(function (response) {
						//handle error
						console.log(response);
					});					

				}, 1000);
			})
		}
	}

	addMoreCustomization=()=>{

		const{ customization } = this.state;

		let customizationlength	= (Object.keys(customization).length)+1;

		let tempCustomization	= [...customization];

		let singlecustomization	= {categoryindex:customizationlength,customizationname:'',customizationtype:2,orderby:'',customoption:[{optionindex:1,option:''}]};

		tempCustomization	= [...tempCustomization, singlecustomization];

		this.setState({
			customization:tempCustomization
		})
	}

	addMoreOption=(categoryindex)=>{

		const{ customization } = this.state;

		let tempCustomization	= [...customization];

		let singlecustomization		= tempCustomization.find(category => category.categoryindex === categoryindex);

		let tempSingleOption	= [...singlecustomization.customoption];

		let optionlength	= (Object.keys(tempSingleOption).length)+1;

		let singleoption	= {optionindex:optionlength,option:''};

		tempSingleOption	= [...tempSingleOption, singleoption];

		singlecustomization.customoption	= tempSingleOption;

		this.setState({
			customization:tempCustomization
		})
	}

	handleCustomizationInput = (e, id, fieldname) =>{

		const value	= e.target.value;

		let tempCustomization		= [...this.state.customization];

		const singleCustomization	= tempCustomization.find(category => category.categoryindex === id);

		if(fieldname === 'customizationname')
		{
			singleCustomization.customizationname = value;
		}
		else if(fieldname === 'customizationtype')
		{
			singleCustomization.customizationtype = value;			
		}
		else if(fieldname === 'orderby')
		{
			singleCustomization.orderby = value;			
		}

		this.setState(()=>{
			return{
				customization:[...tempCustomization]
			};
		})
	}

	handleOptionInput = (e, id, optionid) =>{

		const value	= e.target.value;

		let tempCustomization		= [...this.state.customization];

		const singleCustomization	= tempCustomization.find(category => category.categoryindex === id);
		const singleOption			= singleCustomization.customoption.find(option => option.optionindex === optionid);

		singleOption.option	= value;

		this.setState(()=>{
			return{
				customization:[...tempCustomization]
			};
		})
	}

	updateTableLastActivityTime=()=>{
	
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

		if(tabledetail)
		{
			if(Number(tabledetail.eventid) > 0 && Number(tabledetail.tracktablet) > 0)
			{
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'UpdateTableLastActivityTime');
				bodyFormData.set('eventid', tabledetail.eventid);
				bodyFormData.set('orloid', tabledetail.code);
				bodyFormData.set('cartupdatedtime', tabledetail.cartupdatedtime);
				bodyFormData.set('currentapptime', Date.now());
				bodyFormData.set('cartitemcount', Object.keys(this.state.cart).length);
				bodyFormData.set('intervalcycle', 15000);
	
				axios({
				  method: 'post',
				  url: `${process.env.REACT_APP_API_URL}seatmanagement.php?t=${Date.now()}`,
				  data: bodyFormData,
				  config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
					if(response.data.success !== true)
					{
						console.log(response.data);
					}
				})
				.catch(function (response){
					//handle error
					console.log(response);
				});
			}
		}
	}

	ReassignRunnerToOrder=()=>{
		let eventid		= "";

        if(this.state.searchrunnerid === null || this.state.searchrunnerid === undefined || this.state.searchrunnerid === "")
        {
			this.toastMessage(`Please enter runner id to reassign order.`, 'error');
            document.getElementById("searchrunnerid").focus();
            return false;
        }

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(tabledetail || captiondetail || kotedetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid	= tabledetail.eventid;
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'ReassignNewRunner');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('engagedrunnerid', this.state.searchrunnerid);
        bodyFormData.set('isreprint', Number(1));
        bodyFormData.set('sendrunneronbreak', Number(1));
  
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{
			
			if(response.data.success === true)
			{
				this.setState({
					searchrunnerid:'',
				},()=>{
					this.GetEngagedRunners();
				})
			}
			else
			{
				this.toastMessage(response.data.msg, 'error');
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	toastMessage=(msg, type)=>{
		if(type === 'success')
		{
			toast.success(`${msg}`, {
				position: "bottom-center",
				autoClose: 10000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
		else if(type === 'error')
		{
			toast.error(`${msg}`, {
				position: "bottom-center",
				autoClose: 10000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	}

	toggleConfirm=(recordid, recordid2, message, cbfunctionname)=>{

		this.setState(()=>{
			return{
				isconfirmed:false,
				isdialogopen:!this.state.isdialogopen,
				confirmmessage:message,
				confirmrecordid:recordid,
				confirmrecordid2:recordid2,
				cbfunctionname:cbfunctionname,
			}
		},()=>{
			if(!this.state.isdialogopen)
			{
				this.setState(()=>{
					return{
						isconfirmed:false,
						confirmmessage:'',
						confirmrecordid:'',
						confirmrecordid2:'',
						cbfunctionname:'',
					}
				})
			}
		})
	}

	toggleConfirmAndUpdateCartTime=()=>{

		this.setState(()=>{
			return{
				isconfirmed:false,
				lefttimetoclearcart:9,
                iscartclearingstarted:false,
                isdialogopen:false,
                confirmmessage:'',
			}
		},()=>{
			let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

			if(tabledetail)
			{
				tabledetail  = {...tabledetail, lastitemaddedtime:Date.now()};
				localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
			}
			clearInterval(this.cartTicker);
		})
	}

	handleCategoryServiceStatus=(e, id)=>{

		const value	= e.target.value;

		if((Number(value) === Number(this.state.categoryservicestatus)) && (Number(id) === Number(this.state.checkedcategoryid)))
		{
			return;
		}

		let eventid	= "";

		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));

		if(captiondetail || kotedetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
			else if(Number(kotedetail.eventid) > 0)
			{
				eventid	= kotedetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		const tempallitemcategories	= [...this.state.allitemcategories];

		const singlecategory		= tempallitemcategories.find(category => Number(category.id) === Number(id));

		singlecategory.iscategoryupdating	= true;

		this.setState(()=>{
			return{
				allitemcategories:tempallitemcategories,
			}
		},()=>{

			setTimeout(() => {
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'UpdateCategoryServiceStatus');
				bodyFormData.set('kotid', 1);
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('categoryid', id);
				bodyFormData.set('itemtype', value);
	
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_URL+'category.php',
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{

					if(response.data.success === true)
					{
						singlecategory.categoryservicestatus	= Number(value);
						singlecategory.iscategoryupdating		= false;
		
						this.setState(()=>{
							return{
								checkedcategoryid:id,
								categoryservicestatus:value,
								allitemcategories:tempallitemcategories,
							}
						},()=>{
							this.setKoteProducts(`updatecategorystatus`);
						});
					}
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});

			}, 1000);
		})
	}

	markOrderHasDeliveredByRunner=()=>{

		this.setState({
			isitemmarkdeliveredbyguest:true,
			recentdeliverrunnerid:'',
		},()=>{
			localStorage.setItem("isitemmarkdeliveredbyguest",true);
		},()=>{
			if(this.state.isorderstatusopen)
			{
				this.toggleOrderStatus();
			}
		});
	}

	toggleOrderStatusToRaiseAComplaint=()=>{

        this.setState({
            isorderstatusopen:!this.state.isorderstatusopen,
        },()=>{
			if(this.state.isorderstatusopen)
			{
				this.setState(()=>{
					return{
						showitemforcomplain:true
					}
				},()=>{
					document.body.style.overflow = "hidden";
				})
			}
			else
			{
				this.setState(()=>{
					return{
						showitemforcomplain:false
					}
				},()=>{
					document.body.style.overflow = "auto";
				})				
			}
			this.getOrderedItemDetail();
		});
	}

	toggleNotDeliveredItemsSelection = (id) =>{

		let tempProducts	= [...this.state.orderitemdetails];
		const product		= tempProducts.find(detailitem => detailitem.detailid === id);

		product.isselectedforcomplain	= !product.isselectedforcomplain;

		this.setState(()=>{
			return{
				orderitemdetails:[...tempProducts]
			};
		})
	}

	handleSaveNotDeliveredItemBulk=(isplaceorder)=>{

		let eventid			= "";
		
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

		if(tabledetail)
		{
			if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
			{
				eventid	= tabledetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		if(Object.keys(this.state.orderitemdetails.filter(tempitem => Number(tempitem.isselectedforcomplain) === 1)).length < 1)
		{
			this.toastMessage(`Please select atleast one item to complain`, 'error');

			return;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'ComplainAndPlaceOrderBulk');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('isplaceorder', Number(isplaceorder));
		bodyFormData.set('notdelivereditems', JSON.stringify(this.state.orderitemdetails.filter(tempitem => Number(tempitem.isselectedforcomplain) === 1)));
		bodyFormData.set('orderingtime', Date.now());

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}order.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{
			
			if(response.data.success !== true)
			{
				this.toastMessage(`Unable to process your request, Please try latter`, 'error');
			}
			else
			{
				this.setState(()=>{
					return{
						isorderstatusopen: false,
						showitemforcomplain: false,
					};
				},()=>{
					this.markOrderHasDeliveredByRunner();

					if(isplaceorder === true)
					{
						this.setState({
							isreorderadded: true,
							re_orlo_order_id:response.data.orlo_order_id,
							isreordererror:false,
							isreorderprocessdone:true,
						})
					}
				})
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	searchImageFromGoogle=(area)=>{

		let itemname	= this.state.newitemname;

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			this.toastMessage(`Unable to process your request, Please relogin to verify your details`,`error`);
			return;
		}

		if(area === 'edititem')
		{
			itemname	= this.state.edititem_itemname;
		}

		if(itemname === "" || itemname === undefined || itemname === null)
		{
			this.toastMessage(`Please enter item name to search image`,`error`);
			return;
		}
		if(!window.navigator.onLine && itemname !== "" && itemname !== undefined && itemname !== null)
		{
			this.toastMessage(`Please connect to internet to search image`,`error`);
			return;
		}

		this.setState(()=>{
			return{
				issearchingiamge:true
			}
		},()=>{
			setTimeout(() => {
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'SearchImage');
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('keyword', itemname);

				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}searchimagebygoogle.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
		
					this.setState(()=>{
						return{
							issearchingiamge:false,
						}
					},()=>{
						if(response.data.success !== true)
						{
							this.toastMessage(`${response.data.msg}`,`error`);
	
							this.setState({hasgoogleimages:false});
						}
						else
						{
							this.setState({
								googleimages:response.data.googleimages,
								hasgoogleimages:true,
								src:null,
								croppedImageUrl:null,
								newimagedata:null,
							})
						}
					})
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
			}, 500);
		})
	}

	clearGoogleImages=()=>{
		
		this.setState(()=>{
			return{
				src: null,
				croppedImageUrl: null,
				newimagedata: null,
				hasgoogleimages:false,
				googleimages:[],
			}
		})
	}

	scrollToTop=()=>{
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		});
	}

	resetMenuSelection=()=>{
		
		this.setState(()=>{
			return{
				menutype: 'all',
				eventmenus:[],
				selectdeselecttitle: 'Select All',
				isProductLoaded: false,
			}
		})
	}

	setCaptainTable=()=>{

		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		let temptableid			= "";
		let tempistableselected	= false;
		
		if(Number(captiondetail.eventid) > 0)
		{
			temptableid	= captiondetail.ordertablecode;
			tempistableselected	= true;
		}
		else if(Number(stewarddetail.eventid) > 0)
		{
			temptableid	= stewarddetail.ordertablecode;
			tempistableselected	= true;
		}

		this.setState({
			tableid:temptableid,
			isordereditemloaded:false,
			istableselected:tempistableselected,
			orderitemdetails:[],
			pendingqueue:'',
			waitingtime:'',
			pendingitemqty:0,
			haspendingitem:false,
			showpendingitem:false,
			cancancelitem:false,
			isdetailfetched:true,
			isitemmarkdeliveredbyguest:true,
			recentdeliverrunnerid:''
		},()=>{
			this.getOrderedItemDetail();
		})
	}

	removeImage = (itemid) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let tempProducts	= [...this.state.allproducts];
		const product		= tempProducts.find(item=>item.id === itemid);

		if(!product.isimageremoving)
		{
			product.isimageremoving	= true;

			this.setState(()=>{
				return {
					allproducts:tempProducts
				};
			},()=>{
				setTimeout(() => {
	
					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'RemoveItemImage');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('itemid', itemid);
			
					axios({
						method: 'post',
						url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
						data: bodyFormData,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
					})
					.then(response=>{

						product.isimageremoving	= false;

						this.setState(()=>{
							return{
								allproducts:tempProducts
							}
						},()=>{
							if(response.data.success !== true)
							{
								this.toastMessage(`Unable to process your request, Please try latter`, 'error');
							}
							else
							{
								this.toastMessage(response.data.msg, 'success');
								this.setKoteProducts(`removeimage`);
							}
						})
					})
					.catch(function (response) {
						//handle error
						console.log(response);
					});
	
				}, 1000);
			})
		}
	}

	refreshDemoEventData=()=>{

		if(!this.state.isrefreshing)
		{
			this.setState(()=>{
				return{
					refresheventtitle:'Refreshing..',
					isrefreshing:true,
				}
			},()=>{
				setTimeout(() => {

					let tabledetail	= {};

					const temptabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

					if(temptabledetail && temptabledetail !== null && temptabledetail !== undefined)
					{
						tabledetail	= temptabledetail;
					}

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'SyncData');
					bodyFormData.set('eventcode', localStorage.getItem('democode'));
					bodyFormData.set('eventpassword', localStorage.getItem('democode'));
					bodyFormData.set('donotdownloadimage', 1);
					bodyFormData.set('democode', localStorage.getItem('democode'));
					bodyFormData.set('isdemosync', 1);
					bodyFormData.set('ispreview', Number(tabledetail.ispreview));
			
					axios({
						method: 'post',
						url: `${process.env.REACT_APP_API_URL}syncdata.php?t=${Date.now()}`,
						data: bodyFormData,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
					})
					.then(response=>{
	
						this.setState({refresheventtitle:'Refresh',isrefreshing:false});
			
						if(response.data.success === true)
						{
							this.setEventTables();
						}
						else
						{
							this.toastMessage(response.data.msg, 'error');
						}
					})
					.catch(function (response) {
						//handle error
						console.log(response);
					});
					
				}, 1500);
			})
		}
	}

	processDemoEventData=()=>{

		let loggedineventid		= 0;

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				loggedineventid	= Number(tabledetail.eventid);
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				loggedineventid	= Number(stewarddetail.eventid);
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				loggedineventid	= Number(captiondetail.eventid);
			}
		}		

		if(!this.state.isdemoorderprocessing)
		{
			this.setState(()=>{
				return{
					isdemoorderprocessing:true,
				}
			},()=>{
				setTimeout(() => {
			
					axios({
						method: 'get',
						url: `${process.env.REACT_APP_API_URL}cron_process_demo_orders.php?eventcode=${loggedineventid}&t=${Date.now()}`,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
					})
					.then(response=>{
	
						this.setState({isdemoorderprocessing:false});
			
						if(response.data.success === true)
						{
							this.getOrderedItemDetail();
						}
					})
					.catch(function (response) {
						//handle error
						console.log(response);
					});
					
				}, 1500);
			})
		}
	}

	resetAttachedImage=()=>{
		this.setState({
			src: null,
			croppedImageUrl: null,
			newimagedata: null,
		},()=>{
			document.getElementById('imagefile').value = '';
		})
	}

	checkAndRefreshEvent=()=>{

		let eventid			= "";
		let istablelogin	= false;
		let isstewardlogin	= false;

		let temploggedintabledetail	= {};
		let loggedintype			= "";

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
		let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

		if(tabledetail || captiondetail || stewarddetail)
		{
			if(Number(tabledetail.eventid) > 0)
			{
				eventid			= Number(tabledetail.eventid);
				istablelogin	= true;

				temploggedintabledetail	= tabledetail;

				loggedintype	= 'tabledetail';
			}
			else if(Number(stewarddetail.eventid) > 0)
			{
				eventid			= Number(stewarddetail.eventid);
				isstewardlogin	= true;

				temploggedintabledetail	= stewarddetail;

				loggedintype	= 'stewarddetail';
			}
			else if(Number(captiondetail.eventid) > 0)
			{
				eventid			= Number(captiondetail.eventid);

				temploggedintabledetail	= captiondetail;

				loggedintype	= 'captiondetail';
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'GetEventUpdatedTime');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('istablelogin', Number(istablelogin));
		bodyFormData.set('isstewardlogin', Number(isstewardlogin));

		if(istablelogin)
		{
			bodyFormData.set('tablename', tabledetail.title);
			bodyFormData.set('tableno', tabledetail.code);
			bodyFormData.set('seatid', tabledetail.seatid);
		}

		if(isstewardlogin)
		{
			bodyFormData.set('stewardid', stewarddetail.stewardid);
		}
  
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}eventupdatedtime.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));

			if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
			{
				let lastitemaddedtime  = tabledetail.lastitemaddedtime;

				if(lastitemaddedtime !== "" && lastitemaddedtime !== undefined && lastitemaddedtime !== null)
				{
					if(((lastitemaddedtime+(60000*Number(process.env.REACT_APP_CARTCHECKTIME)) - 10000) < Date.now()) && !this.state.iscartclearingstarted && Number(this.state.cartTotalItem) > 0)
					{
						this.setState(()=>{
							return{
								iscartclearingstarted:true,
								isdialogopen:true,
								confirmmessage:`Your have ${this.state.cartTotalItem} items in cart for over ${Number(process.env.REACT_APP_CARTCHECKTIME)} mins. What would you like to do?\nआपकी कार्ट में ${this.state.cartTotalItem} वस्तुएं ${Number(process.env.REACT_APP_CARTCHECKTIME)} मिनट से ज्यादा से पड़ी हुई हैं, आप क्या करना चाहेंगे?`,
							}
						},()=>{
							this.startCartClearing();
						})
					}
				}
			}
			
			this.updateTableLastActivityTime();

			const {success, latestupdatedtime, bannercategoryid, showbanner} = response.data;
			
			if(success === true)
			{
				if(latestupdatedtime !== temploggedintabledetail.latestupdatedtime)
				{
					temploggedintabledetail	= {...temploggedintabledetail, latestupdatedtime:latestupdatedtime};

					if(istablelogin || isstewardlogin)
					{
						temploggedintabledetail	= {...temploggedintabledetail, bannercategoryid:bannercategoryid, showbanner:showbanner};
					}
					
					this.setState(()=>{
						return{
							loggedintabledetail:temploggedintabledetail,
						};
					},()=>{
						localStorage.setItem(`${loggedintype}`,JSON.stringify(temploggedintabledetail));
						this.setEventBanner();
						this.setEventProducts();
						this.setEventTables();
					})
				}
				else
				{
					if(!this.state.showitemforcomplain)
					{
						this.getOrderedItemDetail();
					}

					this.syncStewardDetail();
					this.checkEngageRunner();
				}
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	startCartClearing=()=>{

		let menuclearingtime = 10;

        this.cartTicker = setInterval(()=>{
          if(menuclearingtime <= 0)
          {
            this.setState({
                lefttimetoclearcart:9,
                iscartclearingstarted:false,
                isdialogopen:false,
                confirmmessage:'',
            },()=>{
                clearInterval(this.cartTicker);
            })
          }
          else
          {
            this.setState({
                lefttimetoclearcart:menuclearingtime
            })
          }
          menuclearingtime -= 1;
        }, 1000);
	}

	setKoteProducts = (type) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetAllItems');
		bodyFormData.set('eventcode', eventid);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

			this.setState(()=>{
				return{
					koteresponsedata:response.data
				}
			},()=>{
				this.setAndParseKoteProducts();
			})
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
	}

	setAndParseKoteProducts=()=>{

		const {menuid} = this.state;

		const {success, products, categories, menus, isproductloaded, allmenus, allcategories} = this.state.koteresponsedata;

		let tempAllProducts			= [];
		let tempAllCategory			= [];
		let tempAllMenu				= [];
		let tempCheckAllCatArr		= [];
		let tempAllCategory2		= [];
		let tempCheckAllMenuArr		= [];
		let tempAllMenu2			= [];
		let tempallcategories		= [];

		if(success === true)
		{
			let storeProducts	= products;
			let storeCategory	= categories;

			if(menuid !== "" && menuid !== undefined)
			{
				tempallcategories	= allcategories.filter(tempcategory => Number(tempcategory.menuid) === Number(menuid));
			}

			let storeMenu		= menus;

			let productsNum		= Object.keys(storeProducts).length;
			let categoryNum		= Object.keys(storeCategory).length;
			let menuNum			= Object.keys(storeMenu).length;

			if(productsNum > 0)
			{
				let item;
				for(item in storeProducts)
				{
					let singleItem		= storeProducts[item];
					let AllsingleItem	= singleItem;

					const catid			= singleItem.catid;
					const price			= singleItem.price;
					const menuid		= singleItem.menuid;
					const zonenumber	= singleItem.zonenumber;

					let canshowallitem	= true;

					AllsingleItem	= {...AllsingleItem, busy:false, hasvote:false, hastag:false, canuseoption:false, canrepeatoption:false, customitemqty:1, baseprice:price, optiontotal:0, iszoneediting:false, tempzonenumber:zonenumber, zonenumber2:'---', haslargeimage2:false, isimageremoving:false, iszoneupdating:false, isinstockupdating:false, islivecounterupdating:false, isselfserviceupdating:false};

					if(this.state.searchProductKote === true && this.state.searchKeywordKote !== undefined)
					{
						if(singleItem.title.toLowerCase().indexOf(this.state.searchKeywordKote.toLowerCase()) !== -1)
						{
							canshowallitem	= true;
						}
						else
						{
							canshowallitem	= false;
						}
					}

					if(canshowallitem === true)
					{
						tempAllProducts = [...tempAllProducts, AllsingleItem];
						if(tempCheckAllCatArr.indexOf(catid) === -1)
						{
							tempCheckAllCatArr.push(catid);
							tempAllCategory2[catid] = 1;
						}
						else
						{
							tempAllCategory2[catid] += 1;
						}

						if(tempCheckAllMenuArr.indexOf(menuid) === -1)
						{
							tempCheckAllMenuArr.push(menuid);
							tempAllMenu2[menuid] = 1;
						}
						else
						{
							tempAllMenu2[menuid] += 1;
						}
					}
				}

				if(categoryNum > 0)
				{
					let cat;
					for(cat in storeCategory)
					{
						const singleCat		= storeCategory[cat];
						const AllsingleCat	= singleCat;
						const id			= singleCat.id;

						if(tempAllCategory2[id] > 0)
						{
							AllsingleCat.count			= tempAllCategory2[id];
							AllsingleCat.categorycount	= tempAllCategory2[id];

							tempAllCategory	= [...tempAllCategory, AllsingleCat];
						}
					}
				}

				if(menuNum > 0)
				{
					let menu;
					for(menu in storeMenu)
					{
						const singleMenu	= storeMenu[menu];
						const AllsingleMenu	= singleMenu;
						const id			= singleMenu.menuid;

						if(tempAllMenu2[id] > 0)
						{
							AllsingleMenu.menucount	= tempAllMenu2[id];
							AllsingleMenu.newcount	= tempAllMenu2[id];

							tempAllMenu	= [...tempAllMenu, AllsingleMenu];
						}
					}
				}

				this.setState(()=>{
					return{
						allproducts:tempAllProducts,
						allitemcategories:tempAllCategory,
						isAllProductLoaded:true,
						eventallmenus:tempAllMenu,
						isproductloaded:isproductloaded,
						allmenus:allmenus,
						alleventmenu:allmenus,
						allcategories:allcategories,
						alleventcategory:tempallcategories,
					};
				});
			}
			else
			{
				this.setState(()=>{
					return{
						allproducts:[],
						allitemcategories:[],
						isAllProductLoaded:true,
						eventallmenus:[],
						isloggedin:true,
						loggedinname:`Captain`,
					};
				});
			}
		}
		else
		{
			this.setState(()=>{
				return{
					allproducts:[],
					allitemcategories:[],
					isAllProductLoaded:true,
					eventallmenus:[],
					isproductloaded:isproductloaded,
					allmenus:allmenus,
					allcategories:allcategories,
					alleventcategory:tempallcategories,
				}
			})
		}
	}

	applySearchProductKote = (e) => {

		const name	= e.target.name;
		const value	= e.target.value;

		this.setState(()=>{
			return{
				[name]:value,
				searchProductKote:value !== "" ? true:false,
			}
		},()=>{
			if(this.searchtimeout) clearTimeout(this.searchtimeout);
			this.searchtimeout = setTimeout(() => {

				this.setAndParseKoteProducts();

			}, 300);
		})
	}

	clearProductSearchKote=()=>{
		
		this.setState(()=>{
			return{
				searchKeywordKote:'',
				searchProductKote:false,
			}
		},()=>{
			this.setAndParseKoteProducts();
		})
	}

	unloadKoteProducts=()=>{
		
		this.setState(()=>{
			return{
				searchKeywordKote:'',
				searchProductKote:false,
				koteresponsedata:{success:false, products:[], categories:[], menus:[]},
			}
		})
	}

	toggleItemManagementModel=(modelname, newstatus)=>{

		this.setState(()=>{
			return{
				modalstatus:{...this.state.modalstatus, [modelname]:newstatus},
                name:'',
                orderby:'',
			}
		})
	}

	handleAddEventMenu=(e)=> {
		e.preventDefault();
		
		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		let message	= "";

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		if((this.state.name === "" || this.state.name === null || this.state.name === undefined) && message === "")
		{
			message = "Please enter name";
		}
		
        if(message !== "")
        {
			this.toastMessage(message, 'error');
        }

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'AddMenu');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('name', this.state.name);
					bodyFormData.set('orderby', this.state.orderby);
					bodyFormData.set('status', this.state.status);

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}menu.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false,
							message:'',
                        });
        
                        let color   = "error";
        
                        if(response.data.success === true)
                        {
							color   = "success";
							
							this.setState(()=>{
								return{
									name:'',
									orderby:'',
									menuid:response.data.recordid,
									modalstatus:{...this.state.modalstatus, addeventmenumodal:false},
								  };
							},()=>{
								this.setKoteProducts(`addmenu`);
							})
                        }
						else
						{
							this.toastMessage(message, `${color}`);
						}
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}

	handleAddEventCategory=(e)=> {
		e.preventDefault();
		
		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		let message	= "";

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		if((this.state.menuid === "" || this.state.menuid === null || this.state.menuid === undefined) && message === "")
		{
			message = "Please select menu";
		}

		if((this.state.name === "" || this.state.name === null || this.state.name === undefined) && message === "")
		{
			message = "Please enter name";
		}
		
        if(message !== "")
        {
			this.toastMessage(message, `error`);
        }

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'AddCategory');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('menuid', this.state.menuid);
					bodyFormData.set('name', this.state.name);
					bodyFormData.set('orderby', this.state.orderby);
					bodyFormData.set('status', this.state.status);

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}category.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false,
							message:'',
                        });
        
                        let color   = "error";
        
                        if(response.data.success === true)
                        {
							color   = "success";
							
							this.setState(()=>{
								return{
									name:'',
									orderby:'',
									categoryid:response.data.recordid,
									validcategoryid:response.data.recordid,
									alleventcategory:response.data.allcategories,
									modalstatus:{...this.state.modalstatus, addcategorymodal:false},
								  };
							},()=>{
								this.setKoteProducts(`addcategory`);
							})
                        }
						else
						{
                            this.toastMessage(`${response.data.msg}`,`${color}`);
						}
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}

	handleMenuServiceStatus=(e, id)=>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		const value	= e.target.value;

		if(Number(value) === Number(this.state.menuservicestatus) && (Number(id) === Number(this.state.checkedmenuid)))
		{
			return;
		}

		const tempallmenus	= [...this.state.eventallmenus];

		const singlemenu		= tempallmenus.find(menu => Number(menu.menuid) === Number(id));

		singlemenu.ismenuupdating	= true;

		this.setState(()=>{
			return{
				eventallmenus:tempallmenus
			}
		},()=>{
			setTimeout(() => {
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'UpdateMenuServiceStatus');
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('menuid', id);
				bodyFormData.set('itemtype', value);
	
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_URL+'menu.php',
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{		  
					if(response.data.success === true)
					{
						singlemenu.menuservicestatus	= Number(value);
						singlemenu.ismenuupdating		= false;
	
						this.setState(()=>{
							return{
								checkedmenuid:id,
								menuservicestatus:value,
								eventallmenus:tempallmenus,
							}
						},()=>{
							this.setKoteProducts();
						});
					}
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
			}, 1500);
		})
	}

	handleEditEventMenu=(e)=> {
		e.preventDefault();
		
		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		let message	= "";

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		if((this.state.name === "" || this.state.name === null || this.state.name === undefined) && message === "")
		{
			message = "Please enter name";
		}
		
        if(message !== "")
        {
			this.toastMessage(`${message}`,`error`);
        }

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'EditMenu');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('recordid', this.state.recordid);
					bodyFormData.set('name', this.state.name);
					bodyFormData.set('orderby', this.state.orderby);
					bodyFormData.set('status', this.state.status);

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}menu.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false,
							message:'',
                        });
        
                        let color   = "error";
        
                        if(response.data.success === true)
                        {
							color   = "success";
							
							this.setState(()=>{
								return{
									isedited:true,
									recordid:'',
									name:'',
									orderby:'',
									modalstatus:{...this.state.modalstatus, editeventmenumodal:false},
								};
							},()=>{
								this.setKoteProducts(`editmenu`);
							})
                        }
						else
						{
							this.toastMessage(`${response.data.msg}`,`${color}`);
						}
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}

	showEditOrderBy = (id) =>{

		let tempProducts	= [...this.state.allproducts];
		const index			= tempProducts.indexOf(this.getLiveItem(id));
		const product		= tempProducts[index];

		product.isorderediting	= !product.isorderediting;

		this.setState(()=>{
			return{
				allproducts:[...tempProducts]
			};
		})
	}

	handleOrderByInput = (e, id) =>{

		const value	= e.target.value;

		let tempProducts	= [...this.state.allproducts];
		const index			= tempProducts.indexOf(this.getLiveItem(id));
		const product		= tempProducts[index];

		product.temporderby	= value;

		this.setState(()=>{
			return{
				allproducts:[...tempProducts]
			};
		})
	}

	saveOrderBy = (id) =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let tempProducts	= [...this.state.allproducts];
		const index			= tempProducts.indexOf(this.getLiveItem(id));
		const product		= tempProducts[index];

		if(!product.isorderupdating)
		{
			product.isorderupdating	= true;

			this.setState(()=>{
				return{
					allproducts:tempProducts
				}
			},()=>{
				setTimeout(() => {

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'UpdateProductOrderBy');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('itemid', id);
					bodyFormData.set('orderby', product.temporderby);

					axios({
						method: 'post',
						url: `${process.env.REACT_APP_API_URL}products.php?t=${Date.now()}`,
						data: bodyFormData,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
					})
					.then(response=>{

						product.isorderediting	= false;
						product.isorderupdating	= false;

						if(response.data.success !== true)
						{
							this.toastMessage(`Unable to process your request, Please try latter`,`error`);
							product.temporderby	= product.orderby;
						}
						else
						{
							this.toastMessage(`${response.data.msg}`,`success`);
							product.orderby	= product.temporderby;
						}

						this.setState(()=>{
							return{
								allproducts:[...tempProducts]
							};
						})
					})
					.catch(function (response) {
						//handle error
						console.log(response);
					});					

				}, 1000);
			})
		}
	}

	handleOptionCategoryIsRequiredSelection = (id, categoryid) =>{

		let tempProducts	= [...this.state.products];

		if(tempProducts !== undefined)
		{
			const index		= tempProducts.indexOf(this.getItem(id));
			const product	= tempProducts[index];
	
			let tempOptionGroup	= product.itemoptions.find(optiongroup => optiongroup.customcatid === categoryid);

			tempOptionGroup.isnotrequiredchecked	= !tempOptionGroup.isnotrequiredchecked;
			tempOptionGroup.showerror				= false;
	
			this.setState(()=>{
				return {
					products:tempProducts,
				};
			})
		}
	}

	reOrderItem = (item)=>{

		const{product_id, qty, itemoption} = item;

		const itemoptioncount	= Object.keys(itemoption).length;

		let tempProducts	= [...this.state.products];

		let customizationerrormsg	= "";

		if(tempProducts !== undefined && !this.state.isitemadding && !this.state.isaddeditemprocessing)
		{
			Promise.all([this.showBusy(product_id)])
			.then(async () => {

				if((Number(this.state.cartTotalItem) >= Number(this.state.eventdetail.throttle_order_items)) && Number(this.state.eventdetail.throttle_order_items) > 0)
				{
					this.toastMessage(`Sorry, Maximum ${this.state.eventdetail.throttle_order_items} item can be ordered at a time.`, 'error');
					return false;
				}
				else
				{
					this.setState({isitemadding:true});

					const index		= tempProducts.indexOf(this.getItem(product_id));
					const product	= tempProducts[index];

					if(product !== undefined)
					{
						const price		= product.price;

						if(product.inCart)
						{							
							this.setState(()=>{
								return{
									isitemadding:false,
									isaddeditemprocessing:false,
								}
							},()=>{
								this.increment(product_id);
								this.toggleOrderStatus();
							})
						}
						else
						{
							product.inCart	= true;

							if(itemoptioncount < 1)
							{
								product.count	= Number(qty);
								product.total	= price*Number(qty);
							}
							else
							{
								product.itemoptions.forEach((optiongroup)=>{

									const tempOrderedOptionGroup	= itemoption.find(tempgroup => Number(tempgroup.categoryid) === Number(optiongroup.customcatid));

									let isitemoptionselected	= false;

									if(tempOrderedOptionGroup !== undefined)
									{
										isitemoptionselected	= true;

										optiongroup.selected	= true;
									}

									optiongroup.options.forEach((option)=>{

										if(tempOrderedOptionGroup !== undefined)
										{
											const tempOrderedOption = tempOrderedOptionGroup.itemoption.find(tempoption => Number(tempoption.optionid) === Number(option.optionid));

											if(tempOrderedOption !== undefined)
											{
												isitemoptionselected	= true;
												option.checked			= true;
											}
										}
									})

									if((Number(optiongroup.isrequired) === 0 && optiongroup.isnotrequiredchecked) || Number(optiongroup.isrequired) === 1)
									{
										if(!isitemoptionselected)
										{
											if(customizationerrormsg === "")
											{
												customizationerrormsg	+= `Please select ${optiongroup.name}`;
											}
											else
											{
												customizationerrormsg	+= `\n Please select ${optiongroup.name}`;
											}
										}
									}
								})
								if(customizationerrormsg !== "")
								{
									this.hideBusy(product_id);
	
									product.inCart	= false;
	
									this.setState(()=>{
										return {
											isaddeditemprocessing:false,
											isitemadding:false,
										};
									})
	
									this.toastMessage(customizationerrormsg, 'error');
									return false;
								}
								product.canuseoption	= false;
								product.canrepeatoption	= false;
	
								let tempcount	= product.customitemqty;
	
								product.count	= tempcount;
								product.total	= price * tempcount;
							}

							await this.state.db.addNewItemInCart(product);
	
							let tabledetail	= JSON.parse(localStorage.getItem('tabledetail'));
	
							if(tabledetail)
							{
								tabledetail  = {...tabledetail, lastitemaddedtime:Date.now(), cartupdatedtime:Date.now()};
								localStorage.setItem(`tabledetail`,JSON.stringify(tabledetail));
							}
	
							this.setState(()=>{
								return {
									canuseoption:false,
									canrepeatoption:false,
									singlecustomizableitem:[],
									isduplicateorder:false,
									products:tempProducts,
									cart:[...this.state.cart, product],
									isaddeditemprocessing:false,
									isitemadding:false,
								};
							},()=>{
								document.body.style.overflow = "auto";
	
								this.hideBusy(product_id);
								this.addTotals();
								this.toggleOrderStatus();
							})
						}
					}
				}
			})
		}
	}

	resetAllPopup=()=>{
		this.setState({
			isveg:false,
			containegg:false,
			showsearch:false,
			searchkeyword:'',
			isProductLoaded:false,
			tables:[],
			captains:[],
			allitemcategories:[],
			itemcategories:[],
			allproducts:[],
			products:[],
			cart:[],
			eventdetail:{showbannerandvideotocaptain:false,sliderinterval:5000, thanksinterval:30},
			isbannerloaded:false,
			isSmallBannerLoaded:false,
			mediaserverurl:null,
			isduplicateorder:false,
			canplaceorder:false,
			isloggedin:false,
			iscaptionlogin:false,
			isstewardlogin:false,
			eventtables:[],
			loggedinname:'',
			canretryorder:false,
			isAllProductLoaded:false,
			defaulteventfile:'',
			defaultbannerfile:'',
			defaultsmallbannerfile:'',
			isdefaultevent:false,
			additionalimageurl:'',
			eventbanner:[],
			eventthanksbanner:[],
			eventsmallbanner:[],
			placingorder:false,
			isordererror:false,
			isorderadded:false,
			orlo_order_id:'',
			isreorderadded:false,
			re_orlo_order_id:'',
			cartSubTotal:0,
			cartTax:0,
			cartTotal:0,
			cartTotalItem:0,
			restaurantid:0,
			searchProduct:false,
			isvegonly:false,
			tempproduct:null,
			isconfirmopen:false,
			internetstatus:"online",
			lastorderedcart:[],
			isduplicateagreeopen:false,
			showeventseat:false,
			tableid:'',
			ordertableid:'',
			ordertablename:'',
			ordertablecode:'',
			ordertableseatid:'',
			ordertablecaptionid:'',
			ordertablerunnerid:'',
			isvipchecked:false,
			orderingtime:0,
			engagedrunners:[],
			searchrunner:false,
			searchRunnerKeyword:null,
			allengagedrunners:[],
			isorderprocessdone:false,
			orderitemdetails:[],
			isdetailfetched:false,
			pendingqueue:'',
			waitingtime:'',
			pendingitemqty:0,
			haspendingitem:true,
			isActiveDetail:false,
			tokenitemdetails:[],
			tokenitemummary:[],
			alleventcategory:[],
			menuid:'',
			categoryid:'',
			itemtypeid:'',
			eventitemtypes:[],
			isnewcategory:false,
			newcategoryname:'',
			newitemname:'',
			newitemnamehindi:'',
			validmenuid:true,
			validcategoryid:true,
			validitemtypeid:true,
			validnewcategoryname:true,
			validnewitemname:true,
			validnewitemnamehindi:true,
			additemformvalid:false,
			isfeaturedcategory:false,
			iscategoyorderchange:false,
			alleventrunners:[],
			isActiveEditCategoryDetail:false,
			editmenuid:'',
			editcategoryid:'',
			editcategorymenuid:'',
			categoryisactive:false,
			categoryorderby:false,
			isactivenotdelivereditem:false,		
			notdelivereditemname:"",		
			notdeliveredhindiitemname:"",		
			notdelivereditemqty:0,		
			notdelivereddetailid:"",
			notdeliveredproduct_id:"",
			notdeliveredhasoption:false,
			notdelivereditemoption:[],
			showfootercartbar:false,
			showpendingitem:false,
			istableselected:false,
			cancancelitem:false,
			footerbarshowing:"",
			isreordererror:false,
			isreorderprocessdone:false,
			isActiveEditItemDetail:false,
			edititem_id:'',
			edititem_menuid:'',
			edititem_categoryid:'',
			edititem_itemtypeid:'',
			edititem_itemname:'',
			edititem_hindiname:'',
			edititem_islivecounter:'',
			edititem_isoutofstock:'',
			edititem_isselfservice:'',
			orderby:'',
			runnerid:'',
			runnername:'',
			mobilenumber:'',
			runnerrecordid:'',
			activecount:0,
			inactivecount:0,
			stewardid:'',
			stewardtabletype:'',
			stewardname:'',
			iscartopen:false,
			istableopen:false,
			canuseoption:false,
			canrepeatoption:false,
			canremoveoption:false,
			isorderstatusopen:false,
			singlecustomizableitem:[],
			tabledetail:[],
			searchrunnerid:'',
			miscdetail:{filtercount:1,haseggitem:false,hasnonvegitem:false,hasvegitem:false,menucount:0,servicestatus:1,isfeedback:1},
			imagepreviewstatus:[],
			engagedtableinfo:[],
			showimageatleft:false,
			autoplayvideo:false,
			isThanksBannerLoaded:false,
			defaultthanksbannerfile:'',
			adstobeshown:{},
			theposition:180,
			isitemadding:false,
			isaddeditemprocessing:false,
			isrunnerstatusprocessing:false,
			showbreakpopup:false,
			showcancelpopup:false,
			showsearch_captain:false,
			searchkeyword_captain:'',
			runneritemdetail:[],
			isallrunnerengage:false,
			showhaveyloadmessage:false,
			showoutfordeliverymessage:false,
			outfordeliverymessage:'',
			showsplitorderpopup:false,
			numberofsplit:2,
			catsummary:'',
			totalitemavailable:0,
			availablerunner:0,
			src: null,
			croppedImageUrl: null,
			newimagedata: null,
			isnonveg: false,
			isallmenu: false,
			menutype: 'all',
			ismenuloaded: false,
			eventmenus:[],
			selectdeselecttitle: 'Select All',
			alleventmenu:[],
			tabletype:false,
			alleventtable:[],
			zonenumber:'',
			edititem_zonenumber:'',
			iscustomization:false,
			customization:[{categoryindex:1,customizationname:'',customizationtype:2,orderby:'',customoption:[{optionindex:1,option:''}]}],
			isconfirmed:false,
			isdialogopen:false,
			confirmmessage:'',
			confirmrecordid:'',
			confirmrecordid2:'',
			cbfunctionname:'',
			categoryservicestatus:'',
			isitemmarkdeliveredbyguest:true,
			showitemforcomplain:false,
			recentdeliverrunnerid:'',
			hasgoogleimages:false,
			issearchingiamge:false,
			isoutfordeliveryshowing:false,
			googleimages:[],
			bannerstatus:1,
			haseventproduct:false,
			refresheventtitle:'Refresh',
			isrefreshing:false,
			isdemoorderprocessing:false,
			loggedintabledetail:{},
			isordereditemloaded:false,
			message:'',
			alertcolor:'',
			isprocessing:false,
			bannerarea:1,
			bannertype:1,
			hasimage:false,
			itempreview:"",
			searchKeywordKote:'',
			searchProductKote:false,
			koteresponsedata:{success:false, products:[], categories:[], menus:[]},
			isproductloaded:false,
			allmenus:[],
			allcategories:[],
			productresponsedata:{success:false,mediaserverurl:'',storemenu:[],menunum:0,categories:[],categorynum:0,miscdetail:{},eventdetail:{},products:[],productsnum:0,cart:[]},
			miscmenus:[],
			modalstatus:{addeventmenumodal:false, editeventmenumodal: false, addcategorymodal:false, editcategorymodal:false, additemmodal: false, edititemmodal: false},
			status:1,
			name:'',
			checkedmenuid:'',
			menuservicestatus:'',
			isedited:false,
			recordid:'',
			menuname:'',
			categoryname:'',
			iscartclearingstarted:false,
			lefttimetoclearcart:10,
			iseventtableitemloaded:false,
			upcommingrunners:[],
		},()=>{
			this.clearCart();
		})
	}

	toggleLargeProductPreview=(preview, title, subtitle)=>{

		this.setState(()=>{
			return{
				islargepreview:!this.state.islargepreview
			}
		},()=>{

			let tempPreview		= preview;
			let tempTitle		= title;
			let tempSubTitle	= subtitle;

			if(!this.state.islargepreview)
			{
				tempPreview		= '';
				tempTitle		= '';
				tempSubTitle	= '';
			}

			this.setState({
				largepreviewimg:tempPreview,
				largepreviewtitle:tempTitle,
				largepreviewsubtitle:tempSubTitle,
			})
		})
	}

	setEventAssigningMenu = async () =>{

		let eventid	= "";

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

		if(tabledetail)
		{
			eventid	= tabledetail.eventid;
		}

		if(Number(eventid) < 1)
		{
			return false;
		}

		axios.get(`${process.env.REACT_APP_DATA_URL}eventdata_${eventid}.json?t=${Date.now()}`) // JSON File Path
		.then( response => {

			let storeMenu		= response.data.menus;
			//let itemimagelargesize		= response.data.itemimagelargesize;
			this.setState(()=>{
				return{
					//itemimagelargesize:itemimagelargesize,
					eventmenus:storeMenu,
					listeventmenus:storeMenu,
				}
			})
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	toggleOrderRemark=()=>{

		this.setState(()=>{
			return{
				isorderremark:!this.state.isorderremark
			}
		})
	}

	handleAddOrderRemark=()=> {

		this.setState(()=>{
			return{
				isorderremark:false
			}
		},()=>{

			this.placeOrder();

		})
	}

	handleAddSteward=(e)=> {
		e.preventDefault();
		
		let message = "";

		if((this.state.stewardid === "" || this.state.stewardid === null || this.state.stewardid === undefined) && message === "")
		{
			message = "Please enter steward id";
		}

		if((this.state.stewardname === "" || this.state.stewardname === null || this.state.stewardname === undefined) && message === "")
		{
			message = "Please enter name";
		}

		if((this.state.kotid === "" || this.state.kotid === null || this.state.kotid === undefined) && message === "")
		{
			message = "Please select a operator";
		}
		
        if(message !== "")
        {
            this.showToastNotification(`${message}`,`danger`);
        }

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'AddSteward');
					bodyFormData.set('stewardid', this.state.stewardid);
					bodyFormData.set('name', this.state.stewardname);
					bodyFormData.set('mobile', this.state.mobilenumber);
					bodyFormData.set('tabletype', this.state.tabletype);
					bodyFormData.set('alleventtable', JSON.stringify([]));
					bodyFormData.set('isviptable', 0);
					bodyFormData.set('operatorid', 1);

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}steward.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false,
							message:'',
                        });
        
                        let color   = "danger";
        
                        if(response.data.success === true)
                        {
							color   = "success";
							
							this.setState(()=>{
								return{
									stewardid:'',
									stewardname:'',
									mobilenumber:'',
								  };
							},()=>{
								document.querySelector('#stewardname').focus();
								this.GetEventTable();
								this.GetAllEventSteward();
							})
                        }
                        this.showToastNotification(`${response.data.msg}`,`${color}`);
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}
	
	render(){
		return (
			<ProductContext.Provider value={{
			...this.state,
                setProducts:this.setProducts,
                handleVegNonVeg:this.handleVegNonVeg,
                handleNonVeg:this.handleNonVeg,
                handleContainEgg:this.handleContainEgg,
                showHideSearch:this.showHideSearch,
                handleChange:this.handleChange,
                setEventTables:this.setEventTables,
                setEventBanner:this.setEventBanner,
                increment:this.increment,
                decrement:this.decrement,
                incrementCustomOption:this.incrementCustomOption,
                decrementCustomOption:this.decrementCustomOption,
                addToCart:this.addToCart,
                RetryPlaceOrder:this.RetryPlaceOrder,
                placeOrder:this.placeOrder,
				removeItem:this.removeItem,
				clearCart:this.clearCart,
				closeconfirm:this.closeconfirm,
				setGuestUser:this.setGuestUser,
				checkDuplicateOrder:this.checkDuplicateOrder,
				setAgreeForDuplicateOrder:this.setAgreeForDuplicateOrder,
				operationtimeago:this.operationtimeago,
				ResetOrderProcess:this.ResetOrderProcess,
				removeoutofstockitem:this.removeoutofstockitem,
				resetDuplicateState:this.resetDuplicateState,
				showError:this.showError,
				showHideEventSeat:this.showHideEventSeat,
				setOrderTableAndAddOrder:this.setOrderTableAndAddOrder,
				handleMarkVipOrder:this.handleMarkVipOrder,
				GetEngagedRunners:this.GetEngagedRunners,
				releaseRunner:this.releaseRunner,
				applySearchRunner:this.applySearchRunner,
				getOrderedItemDetail:this.getOrderedItemDetail,
				setIsdetailFetched:this.setIsdetailFetched,
				viewDetail:this.viewDetail,
				updateProductStatus:this.updateProductStatus,
				handleAddMenu:this.handleAddMenu,
				GetEventCategory:this.GetEventCategory,
				handleUserInput:this.handleUserInput,
				handleIsFeaturedChange:this.handleIsFeaturedChange,
				updateFeaturedStatus:this.updateFeaturedStatus,
				updateCategoryOrderBy:this.updateCategoryOrderBy,
				saveCategoryOrderBy:this.saveCategoryOrderBy,
				updateCategoryStatus:this.updateCategoryStatus,
				GetEventRunner:this.GetEventRunner,
				updateRunnerStatus:this.updateRunnerStatus,
				handleTableChange:this.handleTableChange,
				viewEditCategoryDetail:this.viewEditCategoryDetail,
				handleEditCategoryInput:this.handleEditCategoryInput,
				handleIsActive:this.handleIsActive,
				handleEditCategory:this.handleEditCategory,
				cancelOrderItem:this.cancelOrderItem,
				viewNotDeliveredItemDetail:this.viewNotDeliveredItemDetail,
				handleSaveNotDeliveredItem:this.handleSaveNotDeliveredItem,
				cancelAllPendingItem:this.cancelAllPendingItem,
				handleCancelRunnerOrderByCaptain:this.handleCancelRunnerOrderByCaptain,
				viewEditItemDetail:this.viewEditItemDetail,
				handleEditItem:this.handleEditItem,
				handleAddBanner:this.handleAddBanner,
				handleRunnerAttendance:this.handleRunnerAttendance,
				getSingleRunnerDetail:this.getSingleRunnerDetail,
				markInactiveCategory:this.markInactiveCategory,
				GetEventInactiveCategory:this.GetEventInactiveCategory,
				toggleLargePreview:this.toggleLargePreview,
				toggleCartSummary:this.toggleCartSummary,
				toggleItemOption:this.toggleItemOption,
				toggleItemRepeatOption:this.toggleItemRepeatOption,
				toggleRemoveItemOption:this.toggleRemoveItemOption,
				handleOptionSelection:this.handleOptionSelection,
				incrementCustomItem:this.incrementCustomItem,
				decrementCustomItem:this.decrementCustomItem,
				toggleListTable:this.toggleListTable,
				applySearchProduct:this.applySearchProduct,
				toggleOrderStatus:this.toggleOrderStatus,
				handleRunnerSearchFocus:this.handleRunnerSearchFocus,
				handleRunnerSearchBlur:this.handleRunnerSearchBlur,
				handlecloseRunnerSearch:this.handlecloseRunnerSearch,
				breakRunner:this.breakRunner,
				cancelRunnerOrder:this.cancelRunnerOrder,
				releaseRunnerByID:this.releaseRunnerByID,
				playStopVideo:this.playStopVideo,
				setEventThanksBanner:this.setEventThanksBanner,
				listenToScroll:this.listenToScroll,
				handleShowHideBreak:this.handleShowHideBreak,
				handleShowHideCancel:this.handleShowHideCancel,
				handleSelectDeselectItem:this.handleSelectDeselectItem,
				cancelSelectedItem:this.cancelSelectedItem,
				selectdeselectitem:this.selectdeselectitem,
				applyCaptainSearchProduct:this.applyCaptainSearchProduct,
				cancelRunnerOrderByID:this.cancelRunnerOrderByID,
				checkRunnerStatusAndPlaceOrder:this.checkRunnerStatusAndPlaceOrder,
				showHideHeavyLoadMessage:this.showHideHeavyLoadMessage,
				showHideOutforDeliveryMessage:this.showHideOutforDeliveryMessage,
				handleShowHideSplitOrder:this.handleShowHideSplitOrder,
				splitRunnerOrder:this.splitRunnerOrder,
				translateTextInHindi:this.translateTextInHindi,
				onSelectFile:this.onSelectFile,
				onImageLoaded:this.onImageLoaded,
				onCropComplete:this.onCropComplete,
				onCropChange:this.onCropChange,
				handleMenuSelection:this.handleMenuSelection,
				selectDeselectAllMenu:this.selectDeselectAllMenu,
				GetEventMenu:this.GetEventMenu,
				setLoggedOutGuestUser:this.setLoggedOutGuestUser,
				GetEventTable:this.GetEventTable,
				handleSelectDeselectTable:this.handleSelectDeselectTable,
				resetZoneNumber:this.resetZoneNumber,
				showEditZoneNumber:this.showEditZoneNumber,
				saveZoneNumber:this.saveZoneNumber,
				handleZoneNumberInput:this.handleZoneNumberInput,
				addMoreCustomization:this.addMoreCustomization,
				addMoreOption:this.addMoreOption,
				handleCustomizationInput:this.handleCustomizationInput,
				handleOptionInput:this.handleOptionInput,
				updateTableLastActivityTime:this.updateTableLastActivityTime,
				ReassignRunnerToOrder:this.ReassignRunnerToOrder,
				toastMessage:this.toastMessage,
				toggleConfirm:this.toggleConfirm,
				handleCategoryServiceStatus:this.handleCategoryServiceStatus,
				markOrderHasDeliveredByRunner:this.markOrderHasDeliveredByRunner,
				toggleOrderStatusToRaiseAComplaint:this.toggleOrderStatusToRaiseAComplaint,
				toggleNotDeliveredItemsSelection:this.toggleNotDeliveredItemsSelection,
				handleSaveNotDeliveredItemBulk:this.handleSaveNotDeliveredItemBulk,
				searchImageFromGoogle:this.searchImageFromGoogle,
				onSelectGoogleFile:this.onSelectGoogleFile,
				clearGoogleImages:this.clearGoogleImages,
				scrollToTop:this.scrollToTop,
				resetMenuSelection:this.resetMenuSelection,
				setCaptainTable:this.setCaptainTable,
				removeImage:this.removeImage,
				refreshDemoEventData:this.refreshDemoEventData,
				processDemoEventData:this.processDemoEventData,
				resetAttachedImage:this.resetAttachedImage,
				checkAndRefreshEvent:this.checkAndRefreshEvent,
				setKoteProducts:this.setKoteProducts,
				applySearchProductKote:this.applySearchProductKote,
				clearProductSearchKote:this.clearProductSearchKote,
				unloadKoteProducts:this.unloadKoteProducts,
				toggleItemManagementModel:this.toggleItemManagementModel,
				handleAddEventMenu:this.handleAddEventMenu,
				handleAddEventCategory:this.handleAddEventCategory,
				handleMenuServiceStatus:this.handleMenuServiceStatus,
				viewEditMenuDetail:this.viewEditMenuDetail,
				handleEditEventMenu:this.handleEditEventMenu,
				toggleConfirmAndUpdateCartTime:this.toggleConfirmAndUpdateCartTime,
                showEditOrderBy:this.showEditOrderBy,
                handleOrderByInput:this.handleOrderByInput,
                saveOrderBy:this.saveOrderBy,
                setItemImageLoaded:this.setItemImageLoaded,
                setOrderTableAndAddOrderByTableSelection:this.setOrderTableAndAddOrderByTableSelection,
                handleOptionCategoryIsRequiredSelection:this.handleOptionCategoryIsRequiredSelection,
                reOrderItem:this.reOrderItem,
                resetAllPopup:this.resetAllPopup,
                devInArray:this.devInArray,
                toggleLargeProductPreview:this.toggleLargeProductPreview,
                setEventAssigningMenu:this.setEventAssigningMenu,
                toggleOrderRemark:this.toggleOrderRemark,
                handleAddOrderRemark:this.handleAddOrderRemark,
                releaseRunnerConfirmation:this.releaseRunnerConfirmation,
                toggleReleaseRunnerConfirmation:this.toggleReleaseRunnerConfirmation,
                handleAddSteward:this.handleAddSteward,
			}}
			>
			{this.props.children}
			</ProductContext.Provider>
		);
	}
}

const ProductConsumer = ProductContext.Consumer;

export {ProductProvider, ProductConsumer, ProductContext};