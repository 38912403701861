import React from 'react';
import ReactDOM from 'react-dom';

import './assets/bootstrap-4.3.1/dist/css/bootstrap.css';
import './assets/css/style.css';
import 'font-awesome/css/font-awesome.min.css';

import './assets/css/newstyle.css';
import './assets/css/responsive.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { ThemeProvider } from './Context/Theme';
import { ProductProvider } from './Context/Product';
import { KoteProvider } from './Context/Kote';
import {BrowserRouter as Router} from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<ThemeProvider>
		<ProductProvider>
			<KoteProvider>
				<Router basename={(window.location.hostname === 'www.demo.orlosos.com' || window.location.hostname === 'demo.orlosos.com') ?``:''}>
					<App />
				</Router>
			</KoteProvider>
		</ProductProvider>
	</ThemeProvider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
